import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import * as Yup from "yup";
import { removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { getStorageData } from "framework/src/Utilities";
import { loginRegex } from "../../../components/src/LoginRegex";
export type TPath = {
  "/Settings2": () => void
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  errorFailureMessage:string;
  emailPasswordSchema: any;
  isLoading:boolean;
  previousPath:string;
  openDialogName:string;
  termOfService:{ title: string, content:string };
  privacyStatement:{ title: string, content: string};
  popupContentType: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  apiSignInCallId: string = "";
  getTermsOfServiceApiCallId: string = "";
  getPrivacyStatementCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    //passwordSchema
    let emailPasswordSchema = {
      email: Yup
      .string()
      .email(configJSON.pleaseEnterValidEmail)
      .required(configJSON.pleaseEnterValidEmail),
      password: Yup
      .string()
      .matches(
        loginRegex,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Character. Only Latin characters are allowed.'
      )
      .required(configJSON.pleaseEnterAPassword),
    };

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      errorFailureMessage:"",
      emailPasswordSchema:emailPasswordSchema,
      isLoading:true,
      previousPath: "",
      openDialogName:"",
      termOfService: { title: "", content:""},
      privacyStatement:{ title: "", content: ""},
      popupContentType: ""
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.termsOfServiceApi();
    this.privacyStatement();

    window.history.pushState({'popup': true}, "", "/login")
    window.addEventListener('popstate', this.handlePopState);
    this.handleSendMessage()
    this.getRememberedCredentials().then((data) => {
      if (data) {
        this.setState({ 
          email: data.email, 
          password: data.password, 
          checkedRememberMe: data.checkedRememberMe 
        });
      }
    });
    // Customizable Area End
  }

  // Customizable Area Start
  
  async componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
  }
  
  handlePopState = (event: any) => {
    if (event.state?.popup) {
      window.history.pushState(null, "", "/login");
    }
    this.setState({ openDialogName: "" });
  };

  handleSendMessage = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  resetErrorMessage = () => {
    this.setState({ errorFailureMessage: '' });
  };

  onCancel=()=>{
    this.setState({openDialogName:""})
  }

  openTermOfService=()=>{    
    this.setState({openDialogName: 'termOfService', popupContentType:"termService"}, ()=> {
      this.handleSendMessage()
    })
  }

  openPrivacyStatement=()=>{
    this.setState({openDialogName: 'termOfService', popupContentType:"privacyStatement"}, ()=> {
      this.handleSendMessage()
    })
  }

  termsOfServiceApi(){    
    
    const header = {"Content-Type": configJSON.validationApiContentType}

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
   
    this.getTermsOfServiceApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.termsServiceApi); 

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  privacyStatement(){
    
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
    this.getPrivacyStatementCallId = requestMessage.messageId;
    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.privacyStatementApi); 
    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    
    const header = {"Content-Type": configJSON.validationApiContentType}
    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  doUserLogIn(values: {
    email: any;
    password: any;
    checkedRememberMe: any;
  }) {
    this.setState({errorFailureMessage:''})
    this.setState({
      email: values.email,
      password: values.password,
      checkedRememberMe: values.checkedRememberMe
    })

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: values.email,
      password:values.password,
      remember_me: values.checkedRememberMe
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSignInCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginSignInEndPt
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  fnValidationRes = (responseJson:any)=>{
    if(responseJson !== undefined){
      var arrayholder = responseJson.data;

      if (arrayholder && arrayholder.length !== 0) {
        let regexData = arrayholder[0];
  
        if (regexData && regexData.email_validation_regexp) {
          this.emailReg = regexData.email_validation_regexp;
        }
      }
    }
   this.setState({isLoading:false})
  }
  parseJwtToken = async (token:string) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (char) {
      return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    let parsedObj:{id:number,exp:number} = JSON.parse(jsonPayload)
    let timeStamp = parsedObj.exp * 1000
    let tokenExpiryTime = (new Date(timeStamp)).toISOString()
    await setStorageData("tokenValidTime", tokenExpiryTime)
  }
  
  fnSignUpRes = async (responseJson :any,errorReponse :any)=>{
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      this.saveLoggedInUserData(responseJson);
      const userName = responseJson.meta.account.data.attributes.first_name
      const role = responseJson.meta.account.data.attributes.type
      localStorage.setItem("userName",userName);
      localStorage.setItem("role",role);
      this.parseJwtToken(responseJson.meta.token)
      this.sendLoginSuccessMessage();
      this.setState({errorFailureMessage:""})
      if(this.state.checkedRememberMe){
      this.handleRememberMe();
      }else{
        this.clearRememberMe();
      }
      if(responseJson.meta.account.data.attributes.should_reset_password) {
        await setStorageData("authToken", responseJson.meta.token)
        const msg: Message = new Message(getName(MessageEnum.NavigationNewPasswordMessage));
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
      }
      else if(this.state.previousPath){
        let pathname = this.state.previousPath.split("/")[1];
        pathname = pathname == "request-quote" ? "CustomForm" : pathname;
        let param = this.state.previousPath.split("/")[2] ? this.state.previousPath.split("/")[2] : '';
        pathname = pathname.includes("Enquiries") ? "Enquiries" : pathname                 
        this.props.navigation.navigate(pathname, { id:param });
        removeStorageData("previousPath")
      }
      else {
        const msg: Message = new Message(getName(MessageEnum.NavigationLandingMessage));
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
      }
    }
    else if (responseJson.errors && responseJson.errors[0]) {
      this.setState({errorFailureMessage: responseJson.errors[0].failed_login});
  }
    else {
      this.setState({errorFailureMessage:responseJson.error})
     
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

  fnLoginRes = (responseJson :any,errorReponse :any)=>{
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      this.saveLoggedInUserData(responseJson);
      this.sendLoginSuccessMessage();
      this.openInfoPage();
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
      this.sendLoginFailMessage();
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  fnTermOfServiceRes = (responseJson: { title:string, content:string }[]) =>{
    const res = responseJson[0];
    this.setState({termOfService: { title: "Terms of Service", content: res.content}})
  }

  fnPrivacyStatementRes = (responseJson: { title:string, content:string }[]) =>{
    const res = responseJson[0];
   
    this.setState({privacyStatement: { title: "Privacy Policy" , content: res.content}})
  }

  handleRememberMe = async () => {
    const userCredentials = { 
      email: this.state.email, 
      password: this.state.password, 
      checkedRememberMe: this.state.checkedRememberMe 
    };
  
    const encryptedData = await this.encryptData(userCredentials);
    localStorage.setItem("userCredentials", encryptedData);
  };

  encryptData = async (data:any) => {
    const key = await crypto.subtle.generateKey(
      { name: "AES-GCM", length: 256 },
      true,
      ["encrypt", "decrypt"]
    );
  
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(JSON.stringify(data));
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const encryptedData = await crypto.subtle.encrypt({ name: "AES-GCM", iv }, key, encodedData);
    const encryptedString = Buffer.from(new Uint8Array(encryptedData)).toString("base64");
    const exportedKey = await crypto.subtle.exportKey("jwk", key);
  
    return JSON.stringify({ encryptedString, iv: Array.from(iv), key: exportedKey });
  };

  getRememberedCredentials = async () => {
    const storedData = localStorage.getItem("userCredentials");
    if (!storedData) return null;
  
    const { encryptedString, iv, key } = JSON.parse(storedData);
    const importedKey = await crypto.subtle.importKey("jwk", key, { name: "AES-GCM" }, true, ["decrypt"]);
  
    const encryptedBytes = new Uint8Array(atob(encryptedString).split("").map(char => char.charCodeAt(0)));
  
    const decryptedData = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv: new Uint8Array(iv) },
      importedKey,
      encryptedBytes
    );
  
    return JSON.parse(new TextDecoder().decode(decryptedData));
  };

  clearRememberMe = () => {
    const storedData = localStorage.getItem("userCredentials");

  if (storedData) {
    const parsedData = JSON.parse(storedData);
    
    if (!parsedData.checkedRememberMe) {
      localStorage.removeItem("userCredentials");
    }
  }

  this.setState({ email: "", password: "", checkedRememberMe: false });
  }
  
  
  

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const previousPath = await getStorageData("previousPath")    
    this.setState({previousPath: previousPath})

     if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {

        switch (apiRequestCallId) {
          case this.validationApiCallId: 
            this.fnValidationRes(responseJson);

            break;

          case this.apiSignInCallId:
            this.fnSignUpRes(responseJson,errorReponse);

            break;

          case this.apiEmailLoginCallId:
            this.fnLoginRes(responseJson,errorReponse);
            
            break;
          case this.getTermsOfServiceApiCallId:
            this.fnTermOfServiceRes(responseJson);

            break;
            case this.getPrivacyStatementCallId:
              this.fnPrivacyStatementRes(responseJson);
              break;

        }
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
