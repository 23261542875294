import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  View,
  Text,
  Image,
  Dimensions
} from "react-native";
import {
  Container,
  Button,
  Grid,
  Dialog,
  DialogContent,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  Paper,
  TextField,
  Tooltip,
  styled,
  DialogContentText,
  IconButton,
} from "@material-ui/core";
import CategoriessubcategoriesWebController, {
  Props,
  configJSON
} from "./CategoriessubcategoriesController.web";
import AppHeader from "../../../components/src/AppHeader.web";
import {
  imgLogo,
  createImage,
  imgRightArrow,
  imgClose,
  checkBox,
  carouselArrow, group_plus,
  rightArrow,
  leftArrow,
  imgNotification,
  closeBtnIcon
} from "./assets";
import GenericLabel from "../../../components/src/GenericLabel";
import Slider from "react-slick";
import { loadingImg } from "../../customform/src/assets";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import moment from "moment";
import ClearIcon from '@material-ui/icons/Clear';
// Customizable Area End

export default class Categoriessubcategories extends CategoriessubcategoriesWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.scrollRef = React.createRef(); 
    // Customizable Area End
  }
  // Customizable Area Start
  renderCompanyLogo = (data: any) => {
    if (data.attributes.service_category === "bespoke" && this.state.companyProfilePicture) {
      return (
        <img
          data-testid={`companyprofile-${data.id}`}
          src={this.selectViewCompanyProfile()}
          alt="profile"
          width="33"
          height="33"
          style={{
            border: "3px solid #000",
            width: 36,
            height: 36,
            objectFit: "cover",
            alignSelf: 'flex-start',
            marginLeft: 10,
          }}
        />
      )
    }
  }

  handleScrollRight=()=>{
    if(this.scrollRef.current){
      this.scrollRef.current.scrollBy({left:400, behavior:"smooth"})
    }
  }
  handleScrollLeft=()=>{
    const scrollContainer = this.scrollRef.current;
    const scrollLeft = scrollContainer && scrollContainer.scrollLeft || 0;
    const scrollAm = scrollLeft >= 400 ? 400 : scrollLeft;
    if (scrollContainer) {
    scrollContainer.scrollBy({left:-scrollAm, behavior:"smooth"})
    }
  }
  sortArr = (data: any) => {
    const sortedData: any = [...data].sort((a, b) => {
      if (a.attributes && a.attributes.duration === null) return 1;
      if (b.attributes && b.attributes.duration === null) return -1;
      return parseInt(a.attributes && a.attributes.duration) - parseInt(b.attributes && b.attributes.duration);
    });
    return sortedData || data
  }
  renderSortIcon = (column: any) => {
    if (this.state.sortDirection === 'asc') {
      return <KeyboardArrowDownIcon style={{ color: "#594FF5", verticalAlign: 'middle' }} />;
    } else if (this.state.sortDirection === 'desc') {
      if (this.state.hoverIndex === column) {
        return <KeyboardArrowUpIcon style={{ color: "#594FF5", verticalAlign: 'middle' }} />;
      }
    }
    return <KeyboardArrowDownIcon style={{ color: "#594FF5", verticalAlign: 'middle' }} />;
  };
  renderViewCustomFormPopupPriceDetails() {
    return (
      <View style={{
        backgroundColor: '#DEDCFD',
        borderColor: '#000', borderWidth: 3, padding: 20, paddingTop: 10, paddingBottom: 10, width: 450
      }}>
        <Text style={{ fontFamily: "CircularStdBold", color: "#000000", fontSize: 14, paddingBottom: 20 }}>
          {this.state.customForm && this.state.customForm.attributes
            && this.state.customForm.attributes.service_name}
        </Text>
        <View style={[styles.tableProvision,
        { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text style=
            {styles.fontSm}>
            Provisional Cost</Text>
          <Text
            style={styles.fontSm}>£
            {this.state.customForm && this.state.customForm.attributes && this.state.customForm.attributes.cost_summery && this.state.customForm.attributes.cost_summery.provisional_cost}</Text>
        </View>
        <View
          style={[styles.tableProvision,
          { borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5 }]}>
          <Text style={styles.fontSm}>
            Inc. Add-ons</Text>
          <Text
            style={styles.fontSm}>
            £{this.state.customForm && this.state.customForm.attributes && this.state.customForm.attributes.cost_summery && this.state.customForm.attributes.cost_summery.provisional_addon_cost}</Text>
        </View>
        <View
          style={[styles.tableProvision, {
            borderBottomColor: '#CBD5E1',
            borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5
          }]}>
          <Text
            style={styles.fontSm}>Additional Services</Text>
          <Text
            style={styles.fontSm}>
            £{this.state.customForm && this.state.customForm.attributes && this.state.customForm.attributes.cost_summery && this.state.customForm.attributes.cost_summery.additional_services_cost}</Text>
        </View>
        <View
          style={[styles.tableProvision, {
            borderBottomColor: '#CBD5E1', borderBottomWidth: 1, paddingBottom: 5, paddingTop: 5
          }]}>
          <Text
            style={styles.fontSm}>
            Inc. Add-ons</Text>
          <Text
            style={styles.fontSm}>£
            {this.state?.customForm?.attributes?.cost_summery?.additional_addons_cost}</Text>
        </View>
        <View style={[styles.tableProvision,
        { paddingTop: 10 }]}>
          <Text style={{
            fontFamily: "CircularStdBold",
            fontSize: 16,
            color: '#000'
          }}>{configJSON.subTotal}</Text>
          <Text
            style={{
              fontFamily: "CircularStdBold",
              fontSize: 16,
              color: '#000'
            }}>
            £{this.state?.customForm?.attributes?.cost_summery?.sub_total}
          </Text>
        </View>
      </View>
    );
  }
  renderTableCell(data: any) {
    const { is_service, is_bespoke, base_service_detail } = data.attributes;
    const packageAvailable = base_service_detail?.data?.attributes?.package_available;
    const tableCellStyle = webStyle.tablebodyCell;

    const is_serviceCheck = () => {
      return (
        <span className="tooltiptext" style={{ textAlign: 'left', padding: '5px' }} >
          {
            is_service ? "This package has been Deactivated by London Filmed. Please get in touch with your Account Manager for more information." :
              "This service/package has been deactivated for your organisation."
          }</span>
      )
    }
    const renderReusePackageCell = (isClickable: boolean, is_service: boolean) => (
      <TableCell
        align="left"
        className={isClickable ? "customTableCell" : ""}
        style={isClickable ? tableCellStyle : { ...tableCellStyle, color: 'gray' }}
        onClick={isClickable ? () => this.navigatePreviousCustomForm(data.attributes.id) : undefined}
      >
        {isClickable ? (
          "Reuse Package"
        ) : (
          <div className="tooltip">
            Reuse Package
            {is_serviceCheck()}
          </div>
        )}
      </TableCell>
    );

    if (is_service) {
      if (is_bespoke) {
        return renderReusePackageCell(packageAvailable, true);
      } else {
        return renderReusePackageCell(true, true);
      }
    } else {
      return renderReusePackageCell(false, false);
    }
  }

  handleMouseEnter = (index: any) => {
    this.setState({ hoverIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoverIndex: null });
  };
  renderReusePackageButton() {
    const is_service = this.state.customForm?.attributes?.is_service;
    const is_bespoke = this.state.customForm?.attributes?.is_bespoke;
    const packageAvailable = this.state.customForm?.attributes?.base_service_detail?.data?.attributes?.package_available
    let isDisable = true;

    if (is_service) {
      isDisable = is_bespoke ? !packageAvailable : false;
    }

    const buttonStyle = {
      backgroundColor: isDisable ? '#ccc' : '',
    };

    return (
      <Tooltip title={!is_service ? "This service/package has been deactivated for your organisation." : ""} placement="top">
        <div className="buttonWrapper btn-green w-195">
          <div className="buttonOutline" style={{ backgroundColor: isDisable ? '#ccc' : '#00BFBB' }} ></div>
          <Button
            className="btn-sm w-195"
            style={buttonStyle}
            disabled={isDisable}
            onClick={() => this.navigatePreviousCustomForm(this.state.customForm.id)}
          >
            Reuse Package
          </Button>
        </div>
      </Tooltip>
    );
  }
  sortServices = (data: any) => {
    const sortedData: any = [...data].sort((a, b) => {
      if (a?.attributes?.name === 'AV Packages') {
        return -1;
      } else if (
        'AV Packages' ===
        b?.attributes?.name) { return 1; }
      else if (a?.attributes?.name === 'Virtual Packages') {
        return -1;
      } else if (
        b?.attributes?.name ===
        'Virtual Packages') { return 1; } else if ('Live Streaming Packages' === a?.attributes?.name) {
        return -1;
      } else if (
        b?.attributes?.name ===
        'Live Streaming Packages') { return 1; } else if (a?.attributes?.name === 'Event Filming Packages') {
        return -1;
      } else if (

        'Event Filming Packages' === b?.attributes?.name) { return 1; }
      else if ('Event Photography Packages' === a?.attributes?.name) {
        return -1;
      } else if (
        b?.attributes?.name ===
        'Event Photography Packages') { return 1; }
      else if ('Bespoke Request' === a?.attributes?.name) {
        return 1;
      } else if (
        b?.attributes?.name ===
        'Bespoke Request') { return -1; } else {
        return 0;
      }
    });
    return sortedData || data
  }

  reviewEventStartTime = (name: any, value: any): unknown => {    
    if (name == "Event Start Time" || name == "Event End Time") {
      return this.getTimeString(value);
    }
    if (name == "Event Date") {
      return moment(value, 'DD/MM/YYYY').format("DD/MM/YYYY")
    }
    return value;
  }
  getInquiriesData = (previousCategoryList:any) => {
    return previousCategoryList?.inquiry?.data || [];
  };
  
  checkCategory =(category:any)=>{
    return category === 'all' || category === 'bespoke'
  };

   getResultsSummary = (inquiriesData:any, indexOfFirstRecord:any, indexOfLastRecord:any) => {
    const totalResults = inquiriesData.length;
    const start = totalResults > 0 ? indexOfFirstRecord + 1 : 0;
    const end = totalResults > 0 ? Math.min(indexOfLastRecord, totalResults) : 0;
    return `${start} - ${end} of ${totalResults} results`;
  };

  getPaginationStyle = (itemPage:any,currentPage:any)=>({
    textDecoration: itemPage !== currentPage ? 'underline' : 'none',
    color: itemPage === currentPage ? '#594FF5' : 'inherit',
    backgroundColor: itemPage === currentPage ? 'transparent' : 'inherit',

});

  renderLoading = () => {
    if (!this.state.isLoading) return   
    return <div style={{
      display: 'flex', justifyContent: 'center',
      alignItems: 'center', height: '100%',
      width: "100%", backgroundColor: 'transparent'
    }}>
      <img
        src={loadingImg}
        style={{
          height: "50%", width: "25%"
        }} />
        {this.renderSuspendedModal()}
    </div>
  
  }

  renderAllCategory = () => {
    return this.state.category === 'all' ? 'active' : ''
  }

  renderBespokeCategory = () => {
    return this.state.category === 'bespoke' ? 'active' : ''
  }

  renderPreviousCategory = () => {
    return this.state.category === 'previous' ? 'active' : ''
  }

  renderEventDate = (attributes : any) => {
    if(!attributes.event_date) return ;
    return attributes.event_date
  }

  renderCost = (attributes: any) => {
    if(!attributes.cost_summery) return
    return `£${attributes.cost_summery.sub_total}`
  }

  checkState = (isLargeScreen: any) => {
    return isLargeScreen ? 400 : 370
  }

  checkImageHeight = (isLargeScreen: any) => {
    return isLargeScreen ? 187 : 157;  
  };

  renderCategoryList = (isLoadingPrevious: boolean, inquiriesData: any, currentRecords: any, recordsPerPage: any, indexOfFirstRecord: any, indexOfLastRecord: any) => {
   if(this.checkCategory(this.state.category)){
     return <Grid container spacing={4} data-test-id={"categoryListID"}>
                    {this.state.categoryList?.map((data: {
                      id: string | number, attributes: {
                        image: string,
                        start_from_price: string | number,
                        name: string,
                        description: string
                      }
                    }) => {
                      const windowWidth = Dimensions.get('window').width;
                      const isLargeScreen = windowWidth > 1250;
                      return (
                        <Grid item md={4} sm={6} xs={12} xl={4} key={data.id} data-testid="heightStyle" >
                          <View data-testid="heightStyles" style={{overflow: "hidden",height: this.checkState(isLargeScreen),
    minHeight:this.checkState(isLargeScreen),
    maxHeight:380,
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: '#000',
    display: 'flex', position: 'relative', zIndex: 1 }}>
                            <Image
                              source={{ uri: data.attributes.image }}
                              resizeMode="cover"
                              style={{ height:this.checkImageHeight(isLargeScreen), 
                                minHeight: this.checkImageHeight(isLargeScreen), 
                                maxHeight: 160,
                                width:'100%'}}
                            />
                            <View
                              style={{
                                paddingRight: 20,
                                paddingBottom: 25,
                                paddingTop: 10,
                                paddingLeft: 20,
                                borderTopColor: "#000000",
                                borderTopWidth: 3,
                                backgroundColor: "#fff"
                              }}
                            >
                              <View
                                style={[
                                  styles.flexColumn,
                                  { justifyContent: "space-between", backgroundColor: "#fff" },
                                ]}
                              >
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                  <View style={{ height: 135, backgroundColor: "#fff", flex: 1 }}>
                                    <Text style={{ fontFamily: "CircularStdBold", fontSize: 18, color: "#000", lineHeight: 28, marginBottom: 5 }}>{data.attributes.name}</Text>
                                    <Text style={{ fontSize: 13, fontFamily: "CircularStd", color: "#594FF5", marginBottom: 3 }}>Starts from £{Number(data.attributes.start_from_price).toLocaleString()}</Text>
                                    <Text style={{ overflow: 'hidden', fontSize: 14, color: "#5A5A5A", lineHeight: 18, fontFamily: "CircularStd", marginBottom: 20 }} numberOfLines={3} ellipsizeMode={'tail'}>
                                      {data.attributes.description}
                                    </Text>
                                  </View>
                                  {this.renderCompanyLogo(data)}
                                </View>

                                <div className="buttonWrapper btn-blue" style={{ marginTop: "auto" }}>
                                  <div className="buttonOutline"></div>
                                  <Button
                                    className="btn-sm"
                                    data-testid={`select-package-${data.id}`}
                                    onClick={() => this.setSubcategory(data)}
                                  > {configJSON.selectPackage}</Button>
                                </div>
                              </View>
                            </View>
                          </View>
                        </Grid>
                      )
                    }
                    )}
                  </Grid>
   }else {
    return <View style={{ justifyContent: "center", display: "flex" }}>
                    <View>
                      <div>
                        <Typography variant="h1" style={{ fontSize: '30px', color: '#000', fontFamily: "CircularStd, sans-serif", fontWeight: 700, textTransform: 'capitalize', paddingTop: 20 }}>Previous Packages ({inquiriesData.length})</Typography>
                        <div style={{ background: "#ffffcc", border: "3px solid black", marginBottom: "5px", marginTop: "20px", marginRight: 20 }}>
                          <TableContainer component={Paper} style={{ boxSizing: "border-box", paddingBottom: "60px", paddingLeft: "30px", paddingRight: "10px", paddingTop: "20px", minHeight: 600 }}>
                            {isLoadingPrevious ? (
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60%', width: "100%", backgroundColor: 'transparent' }}>
                                <img src={loadingImg} style={{ height: "30%", width: "10%" }} alt="Loading..." />
                              </div>
                            ) : (
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={webStyle.tableCell}>User Name</TableCell>
                                    <TableCell style={{ ...webStyle.tableCell, cursor: "pointer" }} data-test-id="packageHeaderId" onClick={() => this.handleSortClick('package')}
                                    >Package {this.renderSortIcon('package')}</TableCell>
                                    <TableCell style={{ ...webStyle.tableCell, cursor: "pointer", minWidth: 150 }} data-test-id="eventNameId" onClick={() => this.handleSortClick('eventName')}
                                    >Event Name {this.renderSortIcon('eventName')}</TableCell>
                                    <TableCell style={{ ...webStyle.tableCell, cursor: "pointer", minWidth: 150 }} data-test-id="eventDateId" onClick={() => this.handleSortClick('eventDate')}
                                    >Event Date {this.renderSortIcon('eventDate')}</TableCell>
                                    <TableCell style={webStyle.tableCell}>Amount</TableCell>
                                    <TableCell align="left" style={webStyle.tableCell}>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {currentRecords?.map((d: any) => (
                                    <TableRow key={d.id}>
                                      <TableCell className="customTableCell" style={webStyle.tablebodyCell} onClick={() => this.viewInquiry(d.id)} data-test-id={`name-row`}>{this.getUserName(d.attributes)}</TableCell>
                                      <TableCell className="customTableCell" style={{ ...webStyle.tablebodyCell, maxWidth: 170 }} onClick={() => this.viewInquiry(d.id)}>{d.attributes.service_name}</TableCell>
                                      <TableCell data-test-id={d.attributes.event_name} className="customTableCell" style={{ ...webStyle.tablebodyCell, maxWidth: 140 }} onClick={() => this.viewInquiry(d.id)}>{d.attributes.event_name}</TableCell>
                                      <TableCell className="customTableCell" style={webStyle.tablebodyCell} onClick={() => this.viewInquiry(d.id)}>
                                        {this.renderEventDate(d.attributes)}
                                      </TableCell>
                                      <TableCell className="customTableCell" style={webStyle.tablebodyCell} onClick={() => this.viewInquiry(d.id)}>
                                      {this.renderCost(d.attributes)}
                                      </TableCell>
                                      {this.renderTableCell(d)}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            )}
                          </TableContainer>


                        </div>
                      </div>


                      <div style={{ backgroundColor: "#FFFFFF", height: "64px", boxSizing: "border-box", padding: "15px", display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "14rem", marginLeft: "auto", marginRight: "auto" }}>                    <Pagination
                          data-test-id="testPage"
                          count={Math.ceil(inquiriesData.length / recordsPerPage)}
                          page={this.state.currentPage}
                          defaultPage={3} siblingCount={0} boundaryCount={1}
                          onChange={(event, value) => { this.handlePageChange(value) }}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontFamily: 'CircularStd'
                          }}
                          hidePrevButton={this.state.currentPage === 1}
                          hideNextButton={this.state.currentPage === Math.ceil(inquiriesData.length / recordsPerPage)}
                          renderItem={(item) => (
                            <PaginationItem
                              {...item}
                              component="div"
                              style={
                                this.getPaginationStyle(item.page,this.state.currentPage)}
                            />
                          )}
                        />
                        </div>
                        <Box style={{
                          fontSize: '12px',
                          fontWeight: 'normal',
                          color: '#5A5A5A',
                          margin: '0',
                          lineHeight: '1',
                        }}>

                    <p>{this.getResultsSummary(inquiriesData,indexOfFirstRecord,indexOfLastRecord)}</p>
                        </Box>
                      </div>
                    </View>
                  </View>
   }
  }

  renderYourPackageMessage = () => {
    if(!(this.checkCategory(this.state.category) && this.state.yourPackageMessageBoolean )) return ;
    return <p style={{ fontFamily: "CircularStd", fontSize: 20, paddingLeft: 25, paddingTop: 25 }}>{this.state.yourPackageMessage}...</p>
  }

  showLeftButton = () => {
    if(!this.state.showLeftButton) return ;
    return <button style={{position:"absolute",top:"50%",zIndex: 10,left:8,transform: "translateY(-50%)",border:"none",borderRadius:"100%",backgroundColor:"black", cursor:"pointer"}} onClick={this.handleScrollLeft}>
    <Image source={leftArrow} resizeMode="cover" style={{
     height: '34px',
     width: '30px',
     }} /> 
    </button>
  }

  showRightButton = () => {
    if(!this.state.showRightButton) return ;
    return <button style={{position:"absolute",top:"50%",zIndex: 10,right:8,border:"none",transform: "translateY(-50%)",borderRadius:"100%", backgroundColor:"black", cursor:"pointer"}} onClick={this.handleScrollRight}>
    <Image source={rightArrow} resizeMode="cover" style={{
     height: '34px',
     width: '30px',
     }} /> 
    </button>
  }

  renderSubcategoryName = () => {
    if (Object.keys(this.state.subcategory).length <= 0) return null;
    return  <GenericLabel fontFamily="CircularStdBold" fontSize={28} color="#000">{this.state.subcategory.name}</GenericLabel>
  }

  renderDuration = (data: any) => {
    return data.attributes.duration < 1 ? 'Multi-Day' : `Less than ${data.attributes.duration} hours`
  }

  alignContent = () => {
    return this.state.subcategory.sub_categories.data.length > 3 ? '' : "space-around"
  }

  renderWidth = () => {
    return this.state.subcategory.sub_categories.data.length > 3 ? 1100: `${this.state.subcategory.sub_categories.data.length * 450}px`
  }

  renderSubcategoryDetails = () => {
    if (Object.keys(this.state.subcategory).length <= 0) return null;
    return                  <div style={{display:"flex", position:"relative",overflow:"hidden",justifyContent: this.alignContent(),width:this.renderWidth()}}
                ref = {this.scrollRef}
                >
                  {this.state.subcategory.sub_categories.data?.map((data: {
                    id: string | number, attributes: {
                      name: string,
                      id: number,
                      start_from: number,
                      duration: number,
                      color_theme: string,
                      parent_id: number,
                      actual_price: number,
                      category_id: number,
                      image: string,
                      features: { data: [] },
                    }
                  }, index: number) => {
                    const colorTheme = data.attributes.color_theme;
                    return (
                      <div style={{ flex:"0 0 32%"}} key={index}>
                        <div style={{ backgroundColor: "#DEDCFD", borderColor: "#000", 
                            borderWidth: 3, padding: 20, paddingTop: 20, paddingBottom: 20, 
                            borderStyle: "solid", marginRight: this.state.subcategory.sub_categories.data.length > 3 ? 20 : '',
                            width : this.state.subcategory.sub_categories.data.length < 3 ? 280 : '' }}>
                          <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
                            <div className={`subcategory${index + 1}`} style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 60, width: 60, borderStyle: "solid", borderWidth: 3, borderColor: "#000", backgroundColor: colorTheme }}>
                              <Image
                                source={{ uri: data.attributes.image }}
                                resizeMode="cover"
                                style={{ height: 40, width: 40, }}
                              />
                            </div>
                            <View style={{ paddingLeft: 20 }}>
                              <Text style={{ fontFamily: "CircularStd", fontSize: 14, color: "#000", lineHeight: 20 }}>
                                {this.renderDuration(data)}
                              </Text>

                              <Text style={{ fontFamily: "CircularStdBold", fontSize: 20, color: "#000" }}>
                                {data.attributes.name}
                              </Text>
                            </View>
                          </View>
                          <View>
                            <Text style={{ fontFamily: "CircularStd", fontSize: 14, color: "#000", lineHeight: 20 }}>
                              Package Start From
                            </Text>

                          </View>
                          <View>
                            <Text style={{ fontFamily: "CircularStdBold", fontSize: 40, color: "#000", marginTop: 3, marginBottom: 6 }}>
                              £{Number(data.attributes.start_from).toLocaleString()}
                            </Text>

                          </View>
                          <View>
                            <Text style={{ fontFamily: "CircularStdBold", fontSize: 15, color: "#000" }}>
                              All Included Features
                            </Text>

                          </View>
                          <ol className="feature-list" style={{ listStyle: "none", margin: 0, padding: 0, marginTop: 15, marginBottom: 10, height: 240, overflowY: "scroll" }}>
                            {data.attributes.features.data.map((listData: any) =>
                              <li style={{ display: "flex", alignItems: "center", marginBottom: 10 }} key={listData.id}>
                                <Image
                                  source={checkBox}
                                  resizeMode="cover"
                                  style={{ height: 15, width: 15, marginRight: 10 }}
                                />
                                <Text style={{ fontFamily: "CircularStd", fontSize: 15, color: "#000", lineHeight: 26 }}>
                                  {listData.attributes.name}
                                </Text>

                              </li>
                            )}
                          </ol>
                          <div data-testid="selectPackage" className={`buttonWrapper btn-subcategory${index + 1}`} style={{display:"flex", justifyContent:"center", alignItems:"center", margin: this.state.subcategory?.sub_categories?.data?.length === 3 
    ? "46px 0px 10px 37px" 
    : this.state.subcategory?.sub_categories?.data?.length > 3 
        ? "46px 0px 10px 30px" 
        : "46px 0px 10px 27px"
, width: 220 }}>
                            <div className={`buttonOutline subcategoryBg${index + 1}`}></div>
                            <Button
                              className="btn-sm"
                              data-testid={`selectSubcategory${data.id}`}
                              onClick={() => this.navigateCustomForm(data)}
                            > {configJSON.selectPackage}</Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
  }

  renderStatus = () => {
    if(!this.state?.customForm?.attributes) return;
    return <Text style={{ paddingLeft: 40, fontFamily: "CircularStdBold" }}>
    {this.state.customForm.attributes?.status?.charAt(0).toUpperCase() + this.state.customForm.attributes?.status.slice(1)}
  </Text>
  }

  renderFiles = () => {
    if(!this.state?.customForm?.attributes || !this.state?.customForm?.attributes?.files) return ;
    return <View
    style={{ width: 300 }}>
    <Text style={{ fontFamily: "CircularStdBold", color: "#000000", fontSize: 20, lineHeight: 62 }}>Attachments</Text>
    <div style={{ maxHeight: "126px", overflow: "auto" }}>
      {this.state.customForm.attributes.files.length > 0 ? (this.state.customForm.attributes.files.map((file: any) => (
        <View
          key={file.id}
          style={{ backgroundColor: '#F0F0F0', borderColor: '#000', borderWidth: 3, padding: 5, marginBottom: 10, }}>
          <Text>{file.name}</Text>
        </View>
      ))
      ) : (
        <Text>No Files Added</Text>
      )}
    </div>
  </View>
  }

  renderGroupByAdditionalAddon = () => {
    if(!this.state.groupByAdditionalAddon) return ;
    return this.state.groupByAdditionalAddon.map((service: any) => (
      service.attributes.input_values.data.map((input: any, index: any) => (
        input.attributes.user_input && (
          <Grid item xs={3}
            key={index}>
            <div style={
              { marginBottom: "5px", fontSize: "13px", fontFamily: "CircularStdBold" }}>
              {input.attributes.input_field.name}
            </div>
            <TextField
              className="customInput"
              inputProps={{
                style:
                  { backgroundColor: "#fff", color: "#000" }
              }}
              value={
                input.attributes.user_input}
              variant="outlined"
              fullWidth disabled />
          </Grid>
        )
      ))
    ))
  }

  renderInputValueData = () => {
    if(!this.state.customForm) return ;
    return this.state.customForm?.attributes?.base_service_detail?.data?.attributes?.input_values?.data?.map(
                          (input: any, index: any) => (
                            input.attributes.user_input &&
                            (
                              <Grid item xs={12} sm={6} md={3}
                                key={index}>
                                <div style={
                                  {
                                    marginBottom: "5px", fontSize: "13px",
                                    fontFamily: "CircularStdBold"
                                  }}>
                                  {input.attributes.input_field.name}
                                </div>
                                <Tooltip
                                arrow
                                placement="top"
                                data-test-id="popupInputFields"
                                title={this.reviewEventStartTime(input.attributes.input_field.name, input.attributes.user_input) as string}
                                >
                                <TextField
                                  className="customInput"
                                  inputProps={{
                                    style:webStyle.textFieldStyle,
                                  }}
                                  value=
                                  {
                                    this.reviewEventStartTime(input.attributes.input_field.name, input.attributes.user_input)
                                  }
                                  variant="outlined"
                                  fullWidth disabled />
                                </Tooltip>
                              </Grid>
                            )
                          ))
  }

  renderEnquiyDetailsDialogContent = () => {
    if(this.state.isViewLoading) {
      return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60%', width: "100%", backgroundColor: 'transparent' }}>
                <img src={loadingImg} style={{ height: "30%", width: "10%" }} alt="Loading..." />
              </div>
    }else {
      return <View style={{ paddingBottom: "30px" }} >
                <Box padding={{
                  xs: "15px 30px 25px",
                }}>
                  <div data-testid="hideModal" onClick={this.onCancel}
                    style={{ position: "absolute", right: 20, top: 10, cursor: "pointer", }}>
                    <Image
                      data-testid="closeBtn"
                      source={imgClose} resizeMode="cover" style={{ height: 18, width: 18, }}
                    />
                  </div>
                  <Text style={{
                    fontFamily: "CircularStdBold",
                    color: "#000000", fontSize: 30, paddingBottom: 20,
                    lineHeight: 62
                  }}>
                    Event Details</Text>
                  <View style={{
                    backgroundColor: getStatusColor(this.state?.customForm?.attributes?.status),
                    borderColor: '#000', borderWidth: 3,
                    padding: 5, marginBottom: 20,
                    width: 182,
                  }}>
                    {this.renderStatus()}
                  </View>
                  <div style={{ borderBottom: "1px solid grey" }}></div>
                </Box>
                <View style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 30
                }}>
                  <Grid
                    container
                    spacing={4} >

                    <Grid container spacing={3} className="inputFieldsAlignEnd" style={{ paddingLeft: 15, paddingRight: 30, maxHeight: 250, overflow: "auto", marginBottom: "20px" }}>
                      {this.renderInputValueData()}
                      {this.renderGroupByAdditionalAddon()}
                    </Grid>
                    <Grid item xs={7} style={{ marginRight: "40px" }}>
                      {this.renderViewCustomFormPopupPriceDetails()}
                    </Grid>
                    <Grid item
                      xs={4}>
                      <Grid item
                        xs={4} >
                        {this.renderFiles()}
                      </Grid>

                    </Grid>

                    <View style={{
                      display: "flex", width: "100%",
                      paddingLeft: "1%",
                      paddingTop: 30, paddingRight: 20
                    }}>
                      <Grid container spacing={3}>
                        <Grid item sm={12} style={{
                          display: "flex", justifyContent: "end", flexDirection: "row",
                        }}>
                          <div className={`buttonWrapper w-220 btn-subcategory3`} style={{ marginRight: 20 }}>
                            <div className="buttonOutline" style={{ backgroundColor: "#FF5B74" }}></div>
                            <Button className="btn-sm w-195" onClick={this.onCancel} >Back</Button>
                          </div>
                          {this.renderReusePackageButton()}

                        </Grid>
                      </Grid>
                    </View>

                  </Grid>
                </View>
              </View>
    }
  }

  renderSuspendedModal = () => {
    return (
      <Dialog
        open={this.state.isSuspended}
        onClose={this.handleGoToLoginPage}
        aria-labelledby="suspended-account-dialoge"
        aria-describedby="suspended-account-dialoge-description"
        data-test-id="suspendedModal">
        <Box style={accountSuspendedModal.container} >
          <IconButton
            disableTouchRipple
            disableRipple
            disableFocusRipple
            data-test-id="suspendedModalCloseBtn"
            onClick={this.handleGoToLoginPage}
            style={accountSuspendedModal.crossMarkCloseBtn}>
            <img src={closeBtnIcon} />
          </IconButton>
          <Box style={accountSuspendedModal.modalContentWrapper}>
            <Box style={accountSuspendedModal.crossMark}>
              <ClearIcon style={accountSuspendedModal.crossMarkIcon} />
            </Box>
            <Typography style={accountSuspendedModal.suspendedTitle}>
              {this.state.suspendedTitle}
            </Typography>
            <DialogContent>
              <DialogContentText
                style={accountSuspendedModal.suspendedMessage}
                id="suspended-account-dialoge-description"
                data-test-id="suspendedMessageID">
                {this.state.suspendedMessage}
              </DialogContentText>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>
    )
  }
  getUserName = (data:any) => {
    if (data && data.client_name !== null && data.client_name !== undefined)
      return data.client_name;
    const inputValues = data.base_service_detail?.data?.attributes?.input_values?.data || [];
    const clientNameField = inputValues.find(
      (item:any) => item.attributes.input_field.name === "Client Name"
    );
    return clientNameField?.attributes?.user_input || null;
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { previousCategoryList, currentPage, recordsPerPage, isLoadingPrevious } = this.state
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const inquiriesData = this.getInquiriesData(previousCategoryList);
   const sortedInquiriesData = inquiriesData.sort((a:any, b:any) => {
    const dateA = new Date(a.attributes.approved_at).getTime() || 0;
    const dateB = new Date(b.attributes.approved_at).getTime() || 0;
    return dateB - dateA; 
  });
    const currentRecords = sortedInquiriesData.slice(indexOfFirstRecord, indexOfLastRecord);

    this.renderLoading()
    // Customizable Area End
    return (
      // Customizable Area Start
      <Container disableGutters maxWidth={false}>
        <AppHeader
          logoUrl={imgLogo}
          homeText="Home"
          createImageUrl={createImage}
          helpCenterText="Help Centre"
          userUrl={createImage}
          settingsIcon
          helpCenterNotificationUrl={imgNotification}
          onPressHome={() => this.goToHome()}
          onClickViewProfile={() => this.goToProfile()}
          onClickHelpCenter={() => this.goToHelpCenter()}
          onClickSettings={() => this.goToSettingsFn()}
          onClickViewEnquiries={() => this.goToEnquiryFn()}
          onClickQuote={() => this.goToQuoteFn()}
          data-test-id="testAppHeader"
          quoteUrl={group_plus}
        />
        <View
          style={{
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Container disableGutters maxWidth={false} style={{ paddingLeft: 22, paddingRight: 60, marginBottom: 35 }}>
            <Text style={{ color: '#5A5A5A', marginBottom: 40, fontFamily: "CircularStd, sans-serif", }} onPress={this.goToHome} data-testid="gotoHome">
              Home
            </Text>
            <img src={imgRightArrow} width={10} style={{ marginLeft: 10, marginRight: 10 }} alt="imagRightArrow" />
            <Text style={{ color: '#594FF5', fontSize: 16, marginBottom: 40, fontFamily: "CircularStdBold, sans-serif" }}>
              {configJSON.sType}
            </Text>
          </Container>
          <Box sx={mainContainerCss}>
            <View style={{ width: '262px', height: '100%', padding: 10 }}>
              <Text style={{
                fontSize: 22,
                lineHeight: 29,
                fontFamily: 'CircularStdBold',
                color: '#000',
                marginBottom: 20
              }}>{configJSON.createAccount}</Text>
              <div className="stepper">
                <div className="d-flex flex-column">
                  <View style={{ display: 'flex', flexDirection: "row" }}>
                    <Text style={{
                      width: 27, height: 27, backgroundColor: '#DEDCFD', borderColor: '#000', borderWidth: 2,
                      fontSize: 20, fontFamily: 'CircularStdBold', fontWeight: '700', display: "flex", alignItems: "center", justifyContent: "center"
                    }}
                    >
                      1
                    </Text>
                    <View style={{ marginLeft: 10, width: 190, paddingTop: 6 }}>
                      <Text
                        style={{
                          color: '#594FF5',
                          fontFamily: 'CircularStdBold',
                          fontSize: 16,
                          marginBottom: 10
                        }}
                      >
                        {configJSON.chooseServiceType}
                      </Text>
                      <Text
                        style={{
                          color: '#5A5A5A',
                          fontFamily: 'CircularStd',
                          fontSize: 14,
                          lineHeight: 19,
                          paddingRight: 15
                        }}
                      >
                        {configJSON.supportText}
                      </Text>
                    </View>
                  </View>
                </div>
              </div>
              <div className="d-flex flex-column" style={{ marginBottom: 20 }}>
                <View style={{ display: 'flex', flexDirection: "row" }}>
                  <Text style={{
                    width: 27, height: 27, backgroundColor: '#fff', borderColor: '#5A5A5A', borderWidth: 2, color: '#5A5A5A',
                    fontSize: 20, fontFamily: 'CircularStdBold', fontWeight: '700', display: "flex", alignItems: "center", justifyContent: "center"
                  }}
                  >
                    2
                  </Text>
                  <View style={{ marginLeft: 10, width: 190, paddingTop: 6 }}>
                    <Text
                      style={{
                        color: '#5A5A5A',
                        fontFamily: 'CircularStd',
                        fontSize: 16,
                        marginBottom: 10
                      }}
                    >
                      {configJSON.enterEventDetails}
                    </Text>
                  </View>
                </View>
              </div>
              <div className="d-flex flex-column">
                <View style={{ display: 'flex', flexDirection: "row" }}>
                  <Text style={{
                    width: 27, height: 27, backgroundColor: '#fff', borderColor: '#5A5A5A', borderWidth: 2, color: '#5A5A5A',
                    fontSize: 20, fontFamily: 'CircularStdBold', fontWeight: '700', display: "flex", alignItems: "center", justifyContent: "center"
                  }}
                  >
                    3
                  </Text>
                  <View style={{ marginLeft: 10, width: 190, paddingTop: 6 }}>
                    <Text
                      style={{
                        color: '#5A5A5A',
                        fontFamily: 'CircularStd',
                        fontSize: 16,
                        marginBottom: 10
                      }}
                    >
                      {configJSON.reviewSubmit}
                    </Text>
                  </View>
                </View>
              </div>
            </View>
            <Box sx={packageContainer}>
              <Box sx={tabsContainerCss}>
                <div data-testid="allPackage" className={`buttonWrapper w-195 btn-blue ${this.renderAllCategory()}`} style={{ marginRight: 25 }} onClick={() => this.setCategoryTxt("all")}>
                  <div className="buttonOutline"></div>
                  <Button
                    className="btn-sm" onClick={this.onAllPackagesClick}
                  >{configJSON.allPackage}</Button>
                </div>
                <div data-testid="bespoke" className={`buttonWrapper w-195 btn-yellow ${this.renderBespokeCategory()}`} style={{ marginRight: 25 }} onClick={() => this.setCategoryTxt("bespoke")}>
                  <div className="buttonOutline" style={{ backgroundColor: "#FFC500" }}></div>
                  <Button
                    className="btn-sm" onClick={this.onBespokePackagesClick}
                  >{
                      configJSON.bespokePackage}</Button>
                </div>
                <div data-testid="previous" className={`buttonWrapper w-220 btn-green ${this.renderPreviousCategory()}`} style={{ marginRight: 25 }} onClick={() => this.setCategoryTxt("previous")}>
                  <div className="buttonOutline" style={{ backgroundColor: "#00BFBB" }}></div>
                  <Button
                    className="btn-sm" onClick={this.onPreviousPackagesClick}
                  > {configJSON.previousePackage}</Button>
                </div>
              </Box>
              <View>
                {this.renderCategoryList(isLoadingPrevious, inquiriesData, currentRecords, recordsPerPage, indexOfFirstRecord , indexOfLastRecord)}
                {this.renderYourPackageMessage()}
              </View>
            </Box>
          </Box>

        </View>
        <Dialog id="model_add" open={this.state.showModel} onScroll={this.handleScroll} onClose={this.hideModal} className="modal-category"
          style={{ border: '3px solid black', overflow: 'hidden'}}>
          <DialogContent style={this.renderDialogStyle()}>
            {this.showLeftButton()}
            {this.showRightButton()}
            <View style={{ borderBottomColor: "#CBD5E1", borderBottomWidth: 1, borderStyle: "solid", paddingBottom: 18, marginBottom: 24 }}>
              {this.renderSubcategoryName()}
              <GenericLabel fontFamily="CircularStd" fontSize={14} lineHeight={30} color="#5a5a5a">Please choose the duration you require.</GenericLabel>
            </View>
            <div data-testid="hideModal1" onClick={() => this.hideModal()} style={{ position: "absolute", right: 17, top: 17, cursor: "pointer" }}>
              <Image
                source={imgClose}
                resizeMode="cover"
                style={{ height: 18, width: 18, }}
              />
            </div>
            <Box sx={packageInnerStyle}>
              {this.renderSubcategoryDetails()}
            </Box>

            <div data-testid="hideModalText" onClick={() => this.hideModal()} style={{ paddingTop: 20, paddingBottom: 10, cursor: "pointer", textAlign: "center", paddingRight:15 }}>
              <Text style={{ textDecorationStyle: "solid", textDecorationLine: "underline", color: "#000", fontFamily: 'CircularStd', fontSize: 16 }}>Back</Text>
            </div>
          </DialogContent>

        </Dialog>
        <Dialog data-testid="dialog"
          open={this.state.openDialogName === "ViewEnquiryStatus"}
          className="custom-modal" transitionDuration={0}
          aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" scroll="body" >
          <DialogContent style={{
            padding: "0px", border: "solid 3px #000",
            overflowX: "hidden", minHeight: "10%"
          }} data-test-id="form">
            {this.renderEnquiyDetailsDialogContent()}
          </DialogContent>
        </Dialog>

        <Dialog id="model_add" maxWidth="md"
          open={this.state.openDialogName === "Error 2"} onClose={this.onCancel}
          style={{ border: '3px solid black' }}>
          <ResponsiveDialogContent className="pt-100" style={{
            maxWidth: '100%', width: '480px', flexDirection: 'column',
            display: 'flex', alignItems: 'center', border: '3px solid black', boxSizing: 'border-box', minHeight: '465px',
          }}>
            <div className="closeWrapper" data-testid="hideModal" onClick={this.onCancel} style={{
              position: "absolute", cursor: "pointer",
              top: '20px', right: '20px'
            }}>
              <Image source={imgClose} resizeMode="cover" style={{
                height: '20px',
                width: '20px',
              }} />
            </div>
            <Box className="contentContainer" style={{
              gap: '25px', justifyContent: 'center',
              alignItems: 'center', display: 'flex', flexDirection: 'column',
            }}>
              <Box className="contentImage" style={{
                backgroundColor: '#FF5B74', padding: '10px',
                width: 'fit-content', border: 'solid 3px #000',
              }}>
                <ClearIcon style={{
                  height: '60px', fontSize: '48px', width: '60px',
                  color: 'black'
                }} />
              </Box>
              <Typography className="heading" variant="h5" component="h2" style={{
                color: "#000", fontSize: 30, fontFamily: 'CircularStdBold',
                backgroundColor: 'transparent', textAlign: 'center', marginBottom: "0",
              }}>Oops!</Typography>
              <div className="text" style={{
                color: "#5A5A5A", textAlign: 'center', marginTop: 10, fontFamily: 'CircularStd',
                lineHeight: '19px', maxWidth: 380, fontSize: 14, border: "1px solid #a1a1a1", padding: 15, borderRadius: 5
              }}>{this.state.errorMessage}</div>
            </Box>
          </ResponsiveDialogContent>
        </Dialog>
       {this.renderSuspendedModal()}
      </Container>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const accountSuspendedModal = {
  container:{
    maxWidth: '100%', 
    width: '480px', 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: "center",
    border: '3px solid black', 
    boxSizing: 'border-box' as "border-box", 
    minHeight: '465px',
  },
  modalContentWrapper:{
    display:"flex",
    flexDirection:"column" as "column",
    alignItems:"center",
  },
  crossMark:{
    backgroundColor: '#FF5B74',
    border: 'solid 3px #000',
    width: 'fit-content', 
    padding: '12px',
    marginBottom:12
  },
  crossMarkIcon:{
    height: '55px',
    color: 'black',
    width: '55px',
  },
  crossMarkCloseBtn:{
    position: "absolute" as "absolute", 
    top: '3px', 
    right: '3px'
  },
  crossMarkCloseIcon:{ 
    fontWeight: 900, 
    color: "black", 
    fontSize: "25x", 
    width: "25px", 
    height: "25px"
  },
  suspendedTitle:{
    fontSize:"clamp(1.5rem, 1.3917rem + 0.5333vw, 1.875rem)",
    color:"black",
    padding:10,
    fontWeight:700,
    fontFamily: 'CircularStd',
    marginBottom:14
  },
  suspendedMessage:{
    textAlign:"center" as "center",
    fontSize:"14px",
    fontFamily: 'CircularStd',
    color:"#5A5A5A",
    paddingInline:"20px"
  },
}


const styles = StyleSheet.create({
  fontSm: {
    fontSize: 12, fontFamily: "CircularStd",
  },
  flexColumn: {
    display: 'flex',
    flexDirection: "column",
  },
  cardSubTiitle: {
    fontSize: 18,
    fontWeight: '600',
    paddingTop: 20,
    paddingBottom: 20,
    fontFamily: 'CircularStdBold',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: "row"
  },
  tableProvision: {
    display: "flex", justifyContent: "space-between",
    alignItems: "center", flexDirection: "row",
  },
});
const webStyle = {
  pageCount: {
    fontSize: 12,
    color: '#5A5A5A',
    fontWeight: 'normal',
    lineHeight: 1,
    padding: '5px',
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',

  } as const,
  tableCell: {
    fontSize: '20px',
    color: '#000',
    fontFamily: "CircularStd, sans-serif",
    fontWeight: 700,
    borderBottom: 'none',
    textTransform: 'capitalize',
  } as const,
  tablebodyCell: {
    fontSize: '16px',
    color: '#000',
    fontFamily: "CircularStd, sans-serif",
    fontWeight: 400,
    textTransform: 'capitalize',
    cursor: "pointer",
  } as const,
  FilterData: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    border: '3px solid #000',
    background: '#fff',
    padding: '5px',
    boxSizing: 'border-box',

  } as const,
  btnFilters: {
    borderRadius: 0,
    padding: '0 5px',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#5A5A5A',
    fontFamily: 'CircularStd',
    textTransform: 'capitalize',

  } as const,
  btnFilters1: {
    borderRadius: 0,
    padding: '0 5px',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#5A5A5A',
    fontFamily: 'CircularStd',
    textTransform: 'capitalize',
    borderLeft: '1px solid #000',
    borderRight: '1px solid #000',
  } as const,
  hoverStyle: {
    textDecoration: 'underline',
    color: '#594FF5',
  } as const,
  textFieldStyle:{ 
    backgroundColor: "#fff", 
    color: "#000",  
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis", }
}
const getStatusColor = (status: any) => {
  switch (status) {
    case 'approved':
      return '#00BFBB';
    case 'rejected':
      return '#F87171';
    case 'draft':
      return '#FFC500';
    case 'pending':
      return '#B0B0B0';
    default:
      return '#FFFFFF';
  }
};
const ResponsiveDialogContent = styled(DialogContent)({
  '&.pt-65': {
    paddingTop: '65px'
  },
  '&.pb-80': {
    paddingBottom: '80px'
  },
  '&.pt-100': {
    paddingTop: '100px',
  },
  '&.pt-80': {
    paddingTop: '80px'
  },
  '&.pb-50': {
    paddingBottom: '50px'
  },
  '@media only screen and (max-width:500px)': {
    width: '390px !important',
    minHeight: '340px !important',
    gap: '20px !important',
    '&.pt-65': {
      paddingTop: '55px'
    },
    '&.pt-100': {
      paddingTop: '80px',
    },
    '&.pb-35,&.pb-80': {
      paddingBottom: '40px'
    },
    '&.pb-30,&.pb-50': {
      paddingBottom: '30px'
    },
    '& .closeWrapper': {
      right: '15px !important',
      top: '15px !important'
    },
    '& .closeWrapper >div': {
      height: '18px !important',
      width: '18px !important'
    },
    '& .contentContainer': {
      gap: '18px !important'
    },
    '& .contentImage': {
      padding: '7px !important'
    },
    '& .contentImage >svg': {
      height: '35px !important',
      width: '35px !important'
    },
    '& .calendarImage >div': {
      height: '30px !important',
      width: '30px !important'
    },
    '& .calendarImage': {
      padding: '12px !important'
    },
    '& .heading': {
      fontSize: '24px !important',
      lineHeight: '36px !important'
    },
    '& .text': {
      fontSize: '14px !important',
      lineHeight: '23px !important',
      marginTop: '5px !important',
      maxWidth: '330px !important'
    },
    '& .icalendar-select': {
      maxWidth: '200px !important'
    },
    '& .ContinueButton': {
      width: '150px !important',
      marginBottom: '15px !important'
    },
    '& .warningLogo': {
      width: '60px !important',
      height: '60px !important'
    },
    '& .warningLogo >h2': {
      fontSize: '40px !important'
    },
    '& .proceedButton': {
      marginTop: "20px"
    },
    '& .proceedButton >div>div': {
      width: '100px !important'
    },
    '& .scheduleButton >div>div': {
      width: '160px !important'
    }
  }
})

const mainContainerCss = { 
  display: 'flex',
  flexWrap: "wrap", 
  flexDirection: "row" 
};

const tabsContainerCss = { 
  display: "flex",
  flexWrap: "wrap", 
  flexDirection: "row", 
  marginBottom: 25, 
  alignItems: "center" 
};

const packageContainer = { 
  flex: 1, 
  height: '100%', 
  minWidth: "275px" 
};

const packageInnerStyle = {
  display : "flex", 
  overflow:"hidden",
  position:"relative",
};

// Customizable Area End