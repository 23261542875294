import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const baseURL = require("../../../framework/src/config.js").baseURL;
import { getLoqateEnvironment, getStorageData, removeStorageData } from "../../../framework/src/Utilities";
//@ts-ignore
import _ from "lodash";
import React, { ChangeEvent,FocusEvent } from "react";
import axios, { AxiosProgressEvent }  from 'axios';
import moment, { Moment } from "moment";
import momentTimezone from "moment-timezone";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  data: any
  // Customizable Area End
}

// Customizable Area Start

interface FileType{
  name: string;
  progress: number,
  status: string,
  file: File
}

interface InquiryDataAttributes {
  id: number;
  service_id: number;
  base_service_id: number;
  base_service_detail: BaseServiceDetail;
  extra_services_detail: ExtraServicesDetail;
  lf_admin_approval_required: boolean;
  note: string;
  is_previous: boolean;
  event_date: string;
  input_values: InputValuesData[];
  default_coverages: DefaultCoverage[];
  sub_category_name: string;
  is_service: boolean,
  company_id: number
}

export interface UploadiedFilesType {
  name: string;
  progress: number;
  status: string;
  file: File;
};

interface InquiryData {
  data: {
    attributes: InquiryDataAttributes;
  }
}
interface MenuData {
  id: string;
  type: string;
  attributes: MenuAttributes;
}
interface MenuAttributes {
  id: number;
  input_field_id: number;
  company_input_field_id?: string | number;
  additional_service_id: number;
  user_input?: string;
  cost?: string;
  input_field: MenuInputfield;
  formatted_data: Object;
}
interface MenuInputfield {
  id: number;
  name: string;
  field_type: string;
  section: string;
  options?: string;
  select_option?: string;
  values?: string;
  multiplier?: string;
  default_value?: string;
  note?: string;
  type: string;
  input_field_order: number;
}
  interface InquiryDataAttributes {
    id: number;
    service_id: number;
    base_service_id: number;
    base_service_detail: BaseServiceDetail;
    extra_services_detail: ExtraServicesDetail;
    lf_admin_approval_required: boolean;
    note: string;
    is_previous: boolean;
    event_date: string;
    input_values: InputValuesData[];
    default_coverages: DefaultCoverage[];
    sub_category_name: string;
    is_service: boolean,
    company_id: number
  }

interface CustomError extends Error {
  response: {
    data: {
      message: string;
    };
  };
};

interface DuplicateFileType {
  name: string;
  progress: number;
}

  interface InquiryDataAttributes {
    id: number;
    service_id: number;
    base_service_id: number;
    base_service_detail: BaseServiceDetail;
    extra_services_detail: ExtraServicesDetail;
    lf_admin_approval_required: boolean;
    note: string;
    is_previous: boolean;
    event_date: string;
    input_values: InputValuesData[];
    default_coverages: DefaultCoverage[];
    sub_category_name: string;
    is_service: boolean,
    company_id: number
  }

interface ResponseJson {
  inquiry: InquiryData;
  message: string;
  error:string;
}

interface BaseServiceDetail {
  data: {
    attributes: {
      input_values: {
        data: InputValuesData[]
      }
    }
  }
}

interface ExtraServicesDetail {
  data: ExtraServiceData[];
}

interface ExtraServiceData {
  attributes: {
    input_values: {
      data: InputValuesData[];
    };
    service_id: number;
    base_service_id: number;
    service_name: string;
    default_coverages: {
      data: DefaultCoverage[];
    };
  };
}

interface InputValuesData {
  id: number;
  attributes: {
    id: number;
    user_input: string;
    input_field: {
      section: string;
      name: string;
    };
  };
}

interface DefaultCoverage {
  category: string;
  attributes: {
    category: string;
    title: string;
  };
}

interface AddonCoverage {
  [serviceName: string]: {
    [category: string]: string[];
  };
}

interface GroupBySection {
  [key: string]: InputValuesData[];
}

interface GroupByCoverage {
  [key: string]: DefaultCoverage[];
}

interface FieldsResponse {
  [key: string]: string;
}
interface ManageAddistionalServiceType {
  extra_services_detail: {
    data: {
      id: string
      type: string
      attributes: {
        id: number
        service_id: number
        inquiry_id: number
        is_valid: boolean
        sub_category_price: number
        addon_price: number
        service_name: string
        package_available: boolean
        default_coverages: null
      }
    }[]
  }
}

// Customizable Area End

interface S {
  // Customizable Area Start
  token: string;
  errorMessagess:any;
  selectedSuggestion:any;
  suggestionsLocation: any[],
  isInvalidLocation:any
  customForm: any;
  previouslyValidated:any;
  groupByCoverage: any;
  base_service_detail: any;
  groupBySection: any;
  initialValueField: any;
  coverageAccordion: boolean;
  groupByRequiredInformation: any;
  requiredInfoAccordion: boolean;
  categoryBaseID: number;
  categoryId: number;
  packageSlectedList: any[];
  isDropdownOpen: boolean;
  packageSelectedItem: any[];
  additionalServicesSubCategories: any[];
  groupByAddons: any;
  addonCoverage: any;
  eventDetails: {
    title: string;
    description?: string;
    startTime: string;
    endTime: string;
    location?: string;
    durationError: string;
  };
  groupByServices:any;
  groupByAdditionalAddon:any[];
  additionalCoverages1: any[];
  duplicateUploadedFiles: DuplicateFileType[];
  isTimeGiven: boolean;
  uploadedFiles: any[],
  note: string;
  calendarType: any;
  errorMessage: string;
  timeError: boolean;
  openDialogName: string;
  daysCoverageLastValue: string;
  selecteddays: boolean;
  category: string,
  filterEnquiries: string,
  status: string,
  isVeiwEnquiry: boolean,
  selectedDocFile: File | null,
  categoryList: any,
  currentPage: number;
  recordsPerPage: number;
  categoryRecordLength: number;
  warningMessage: string;
  warningMessageBody: {
    input_value_id: number;
    name: string;
    error: string;
  },
  address: any;
  postcode: string;
  openModal: boolean;
  errorPostCode: boolean;
  locationCheck: boolean;
  openToExpertDialog: boolean;
  meetWithExpertDialog: boolean;
  approveDialogState: boolean;
  event: any;
  rejectState: boolean;
  isLoading: boolean;
  hubSpotName: { name: string, error: string };
  sortDirection: any;
  requiredInputFieldsValue: any,
  enquiryDetails: any,
  continueToReview: boolean,
  isBespokeRequest: boolean,
  showOption: boolean,
  currentEventData: Date,
  previousEventDateLessThenCurrentDate: boolean,
  dupModalOpen: boolean,
  previousEventDateEqualCurrentDate: boolean,
  draftEnquiryResponse: { lf_admin_approval_required: boolean }
  isCalculateCost: boolean,
  maxUploadLimitReached: boolean,
  isEventStartTime: boolean,
  isEventDateChanged: boolean,
  isEventTimePopulated: boolean,
  is_service: boolean,
  initializePackageSelectedItem: boolean;
  errorCell: boolean;
  isInfoMessage: boolean;
  infoMessage: {
    infoText: string,
    buttonText: string,
  };
  isDialogOpen: boolean,
  isAVSelected: boolean;
  submissionType: string;
  companyLocations: string[];
  company_id: number;
  isMoreStageOfCoverage:boolean,
  EnquiryData:any,
  isSuspended : boolean ;
  suspendedMessage : string;
  suspendedTitle: string;
  initialLoad: boolean;
  conditionalDaysCoverage: string;
  timeValid:{
    isStartTimeInvalid: boolean,
    isEndTimeInvalid: boolean,
  },
  isOpen: boolean
  manageAdditionalServiceDetails:ManageAddistionalServiceType;
  selectedAdditionalSubcategory: string
  searchParam : string | null
  noResults: boolean;
  typingTimeout: any;
  userTyped: boolean;
  hasSelected: boolean,
  timeInputVal: any,
  timeSelectedVal: any,
  menuWidth: any,
  isFilesUploaded: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  navigation: any;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getInquiryId: any;
  getCategoriesApiCallId: any;
  getAddonApiId: any;
  fileInput: React.RefObject<any>;
  selectRef:React.RefObject<any>;
  saveInquiryApliCallId: any;
  submitInquiryAPICallID: any;
  draftInquiryAPICallID: any
  loqateApiCallId : any;
  multipleAttachmentAPICallID:any;
  calculatedAmountAPiCallId: any;
  changeEnquiresAPICallID: any;
  getMessageApiCallId: any;
  createInquiryId: any;
  createInquiryCoverageId:any;
  getUserListApiMethod: any;
  // keyPostCode: string = "";
  approveKey: string = "";
  postalCodeKey: string = "";
  rejectKey: string = "";
  removeAddonApiId: any;
  saveProjectDescriptionApiCallId: string = ""
  uploadAttachmentsApiCallId: string[] = [];
  successfulUploadedFile: UploadiedFilesType[] = [];
  uploadAttachmentsApiCallIdRemove: string = "";
  copyFilesArray: UploadiedFilesType[] = [];
  getLocationAPICallId: any;
  userSuspendedApiCallId : string  =  "";
  updateAdditionalServiceCostID:string = ""; 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.fileInput = React.createRef();
    this.selectRef = React.createRef();
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      customForm: {},
      suggestionsLocation: [{}],
      previouslyValidated:false,
      isInvalidLocation:false,
      selectedSuggestion:null,
      errorMessagess:"",
      groupByCoverage: {},
      base_service_detail: {},
      groupBySection: {},
      status: "",
      eventDetails: {
        title: "",
        location: "",
        startTime: "",
        endTime: "",
        durationError: "",
      },
      initialValueField: {},
      coverageAccordion: true,
      groupByRequiredInformation: [],
      requiredInfoAccordion: true,
      categoryId: 0,
      categoryBaseID: 0,
      isVeiwEnquiry: false,
      isLoading: true,
      addonCoverage: [],
      isDialogOpen: false,
      event: [],
      calendarType: "calendarIcon",
      selectedDocFile: null,
      packageSlectedList: [],
      isDropdownOpen: false,
      packageSelectedItem: [],
      additionalServicesSubCategories: [],
      daysCoverageLastValue: '',
      sortDirection:null,
      groupByAddons:[],
      groupByServices:[],
      groupByAdditionalAddon:[],
      additionalCoverages1:[],
      duplicateUploadedFiles: [],
      isTimeGiven: false,
      note:"",
      showOption:false,
      errorMessage:"",
      timeError: false,
      uploadedFiles: [],
      openDialogName: "",
      category: "",
      filterEnquiries: "day",
      selecteddays: false,
      warningMessage: "",
      warningMessageBody: {
        input_value_id: 0,
        name: "",
        error: "",
      },
      categoryList: [],
      currentPage: 1,
      recordsPerPage: 12,
      categoryRecordLength: 0,
      address: [],
      postcode: "",
      openModal: false,
      errorPostCode: false,
      locationCheck: false,
      openToExpertDialog: false,
      meetWithExpertDialog: false,
      approveDialogState: false,
      rejectState: false,
      hubSpotName: { name: "", error: "" },
      requiredInputFieldsValue: {},
      enquiryDetails: { isEnquiry: false, id: null },
      continueToReview: false,
      isBespokeRequest: false,
      currentEventData: new Date(),
      previousEventDateLessThenCurrentDate: false,
      dupModalOpen: false,
      previousEventDateEqualCurrentDate: false,
      draftEnquiryResponse: { lf_admin_approval_required: false },
      isCalculateCost: false,
      maxUploadLimitReached: false,
      isEventStartTime: false,
      isEventDateChanged: false,
      isEventTimePopulated: false,
      is_service: true,
      initializePackageSelectedItem: true,
      errorCell: true,
      isInfoMessage: false,
      infoMessage: {
        infoText: '',
        buttonText: '',
      },
      isAVSelected: false,
      submissionType: '',
      companyLocations: [],
      company_id: 0,
      isMoreStageOfCoverage: false,
      EnquiryData:'',
      isSuspended : false,
      suspendedMessage : "" ,
      suspendedTitle: "",
      initialLoad: false,
      conditionalDaysCoverage: '',
      timeValid:{
        isStartTimeInvalid: false,
        isEndTimeInvalid: false,
      },
      isOpen: false,
      manageAdditionalServiceDetails:{} as ManageAddistionalServiceType,
      searchParam: null,
      selectedAdditionalSubcategory: '',
      noResults: false,
      typingTimeout: null,
      userTyped: false,
      hasSelected: false,
      timeInputVal: "",
      timeSelectedVal: "",
      menuWidth: null,
      isFilesUploaded: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.setSumitCustomForm = _.debounce(this.setSumitCustomForm.bind(this), 500);
    this.setCustomFormProjectDescription = _.debounce(this.setCustomFormProjectDescription.bind(this), 500);
    this.getAddons = _.debounce(this.getAddons.bind(this), 500);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseToken) === message.id) {
      await this.handleSessionResponseToken(message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))
      if(apiRequestCallId === this.saveInquiryApliCallId && errorReponse)
      {
        this.setState({ openDialogName: "ErrorForPackages", errorMessage : "Network unavailable. Please check your internet connection."});
      }
      await this.handleRestAPIResponseMessage(message);
    
    
      const apiHandlers = {
        [this.saveInquiryApliCallId]: () => this.handleSaveInquiry(),
        [this.submitInquiryAPICallID]: () => this.handleSubbmmitResFn(responseJson),
        [this.approveKey]: () => this.handleApproveResFn(responseJson),
        [this.draftInquiryAPICallID]: () => this.handledraftResFn(responseJson),
        [this.rejectKey]: () => this.handleRejectResFn(responseJson),
        [this.createInquiryId]: () => {
          this.onHandleInquiryResponse(message);
          this.handleInquiryDataInNewPage(responseJson)
        },
        [this.userSuspendedApiCallId] : () => this.handleIsUserSuspended(message),
        [this.updateAdditionalServiceCostID] : () => this.calculatedAmount(),
        [this.createInquiryCoverageId]: () => {
          this.onHandleCoverageResponse(responseJson);
        },
        [this.loqateApiCallId]: () => {
          console.log(responseJson,"response@@@ 111111")
          this.locateLocation(responseJson);
        },
      };
    
      const handler = apiHandlers[apiRequestCallId];
      if (handler) handler();
    }   
    // Customizable Area End
  }

  // Customizable Area Start

  handleInquiryDataInNewPage = ( responseJsone: any ) => {
    const search = new URLSearchParams(window.location.search)
    const inqArray = responseJsone?.inquiries?.data?.filter((inq: any) => inq.id === search.get('id'))
    const inqObject = inqArray?.[0] || {};
    if(inqObject) { 
      return this.setState(
      { 
        isLoading: false, 
        EnquiryData: inqObject
      }
    )};
  }

  handleOpenModel = () => {
    this.setState({ isOpen : true})
  }

  handleCloseModel = () => {
    this.setState({ isOpen : true})
  }

  onHandleInquiryResponse = (inquiryResponse: Message) => {
    const responseJson = inquiryResponse.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      const { id, service_id, base_service_detail } = responseJson.inquiry.data.attributes;
      this.redirectToAVPacks(responseJson, base_service_detail, service_id, id);
    } else {
      this.setState({ openDialogName: configJSON.error2, errorMessage: configJSON.unExpectedError })
    }
  }
  onHandleCoverageResponse = (responseJson:any) => {
    if (responseJson && responseJson.inquiry && responseJson.inquiry.data.attributes.id) {
      const inquiryId = responseJson.inquiry.data.attributes.id;
      this.props.navigation.navigate("CustomForm", {
        id: inquiryId,
      })
      window.location.reload()
    } 
  }

  locateLocation = (response:any) => {
    if(response) {
    this.setState({
      suggestionsLocation: response,
      noResults: response.length === 0,
      openModal: response.length > 0
    });
   }
  else {
    this.setState({ suggestionsLocation: [], noResults: true, openModal: false });
  }
  }

  groupByCoverage(responseJson: any) {
    return responseJson.inquiry.data.attributes.default_coverages.reduce((group: GroupByCoverage, product: DefaultCoverage) => {
      const { category } = product;
      group[category] = group[category] ?? [];
      group[category].push(product);
      return group;
    }, {});
  }

  uploadResponse(responseJson: { message: string }) {
    this.duplicateAlertModalOpen(responseJson?.message);
    const fileName = responseJson.message.split("'")[1];
    if (fileName) {
      const newUploadedFile = this.copyFilesArray.find((item: UploadiedFilesType) => item.name === fileName);
      if (newUploadedFile) {
        this.successfulUploadedFile.push(newUploadedFile);
        this.setState({ uploadedFiles: this.successfulUploadedFile });
      }
    }
  }

  duplicateAlertModalOpen(data: string) {
    if (data.includes("File must be unique, can't upload the same file")) {
      this.setState({ dupModalOpen: true });
      const uniqueFileNames = new Set();
      let uniqueFiles: { name: string, progress: number, status: string, file: File }[] = [];
      this.state.uploadedFiles.forEach(obj => {
        if (!uniqueFileNames.has(obj.name)) {
          uniqueFileNames.add(obj.name);
          uniqueFiles.push(obj);
        }
      });
      this.setState({ uploadedFiles: uniqueFiles })
    }
  }


  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    const currentPath = window.location.pathname;
    this.setState({ initialLoad : true})
    
    if (!!currentPath.includes("Drafts")) {
      this.setCategoryTxt("draft enquiries")
    } 
    else if (!!currentPath.includes("Pending")) {
      this.setCategoryTxt("pending enquiries")
    } else if (!!currentPath.includes("Approved")) {
      this.setCategoryTxt("approved enquiries")
    }else {
      let enquiry = await getStorageData("enqiry");
      this.getcategoryList(enquiry ? enquiry : "");
    }
    const url = window.location.pathname
    const search = new URLSearchParams(window.location.search)
    this.handleURL(url)
    if (url.includes("Enquiries") && search.get('id')) {
      await this.getInquiry(search.get('id'));
    }
    this.setState({ sortDirection: "asc", searchParam: search.get('id') })
    document.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener("mousemove", this.checkIsUserIsSuspended)
    window.addEventListener("resize", this.handleSelectWidth)
  }

  handleAVSelection = () => {
    const isAVSelected = this.state.additionalServicesSubCategories.some(item => item?.title === 'AV Packages') 
                          || (this.state.base_service_detail && this.state.base_service_detail.service_name === 'AV Packages')
                          || (this.state.base_service_detail && this.state.base_service_detail?.service_name?.includes('AV Packages'));
    return isAVSelected;
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.customForm != this.state.customForm && this.state.customForm?.attributes) {
      this.setState({
        uploadedFiles: this.state.customForm.attributes?.files?.map((file: any) => ({
          name: file.name,
          progress: 100,
          status: 'Completed',
          file: file,
        })) || []
      });
    }

    const isAVSelected = this.handleAVSelection();
    if (isAVSelected !== prevState.isAVSelected) {
      this.setState({ isAVSelected });
    }

  }

  async componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener("mousemove", this.checkIsUserIsSuspended)
  }

  checkIsUserIsSuspended = _.throttle(async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      "token": await getStorageData("authToken")
    };
    this.userSuspendedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.suspendedUserEndPoint 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  },9000)

  handleIsUserSuspended = async (message:Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const checIsTokenExpired = async (): Promise<boolean> => {
      const tokenValidTime = await getStorageData("tokenValidTime")
      const currentTime = new Date().toISOString()
      if (!tokenValidTime) return false
      return new Date(tokenValidTime) < new Date(currentTime)
    }

    if (responseJson.status && responseJson.status === "suspended") {
      this.setState({
        isSuspended: true,
        suspendedMessage: responseJson.message,
        suspendedTitle: configJSON.supsendedModalTitle
      })
    } else if (responseJson.status && responseJson.status === "deactivated") {
      this.setState({
        isSuspended: true,
        suspendedMessage: responseJson.message,
        suspendedTitle: configJSON.deactivatedTitle
      })
    } else if (await checIsTokenExpired()) {
      this.handleGoToLoginPage()
    }
  }

  handleGoToLoginPage = async () => {
    await removeStorageData("authToken")
    await removeStorageData("role")
    await removeStorageData("tokenValidTime")
    await removeStorageData("userName")
    this.setState({ isSuspended: false, suspendedMessage: "", suspendedTitle: "" })
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  async handleSessionResponseToken(message: Message) {
    let token = message.getData(getName(MessageEnum.SessionResponseData));
    if (token === undefined) {
      token = await getStorageData("authToken");
    }
    this.setState({ token: token }, () => {
      this.getInquiry();
    });
  }


  handleSubEnquiryResFn = (userRole: any, responseJson?:any) => {
    if (userRole == "ClientAdmin") {
      const isPrevious = this.state.customForm.attributes.is_previous;
      const isBespokeRequest = this.state.customForm.attributes.base_service_detail?.data?.attributes?.service_name === "Bespoke Request"
      const lfAdminApproval = this.state.draftEnquiryResponse.lf_admin_approval_required
      if(responseJson?.inquiry?.data?.attributes?.status === "approved"){
      this.setState({ openDialogName: "submit successfully ClientAdmin" })
      return;
    }
      if (!isPrevious && isBespokeRequest === false || isPrevious && lfAdminApproval === false) {
        this.approveApiFN();
      }else{
        this.setState({ openDialogName: "submit successfully ClientAdmin" })
      }
    } else {
      this.setState({ openDialogName: "submit successfully ClientUser" })
    }
  }

  handleIsCalculateCost = () => {
    this.setState({ isCalculateCost: true })
  }

  handleEventTime = (name: string) => {
    if (name === 'Event Start Time') {
      this.setState({ isEventStartTime: true, isEventDateChanged: true });
    }
    if (name === 'Event Start Time' || name === 'Event End Time') {
      return name;
    }
  }

  handlePickerChange = (newTime: any, inputId: string, setFieldValue: Function, name:any) => {
    if (moment(newTime, "HH:mm", true).isValid()) {
      this.saveEventTime(newTime, name, inputId, setFieldValue)
    }
  };
  
  handleInputChangeTimes = (event: any, inputId: string, setFieldValue: Function) => {
    const value = event.target.value;
    let numericInput = value.replace(/\D/g, '');
    let sanitizedInput = this.sanitizeTimeInputField(numericInput);
    let formattedTime = sanitizedInput;
    if (sanitizedInput.length > 2) {
      formattedTime = `${sanitizedInput.substring(0, 2)}:${sanitizedInput.substring(2, 4)}`;
    }
    setFieldValue(inputId, formattedTime);
  };
  
  handleBlurChangeTimes = (event: any, inputId: string, setFieldValue: Function, currentValue: string, name:any) => {
    if (moment(currentValue, "HH:mm", true).isValid()) {
      this.saveEventTime(event, name, inputId, setFieldValue)
    } else {
      setFieldValue(inputId,  "");
    }
  };

  /*istanbul ignore next */
  handleTimeChange = (event: any | Moment, setFieldValue: any, inputId: string) => {
    let selectedTime;
    if (typeof event === "string") {
      selectedTime = event;
    } else if (event && typeof event === "object" && event.isValid && event.isValid()) {
      selectedTime = event.format("HH:mm"); 
    }
    if (selectedTime) {
      const formattedTime = moment(selectedTime, "HH:mm").format("HH:mm");
     setFieldValue(inputId, formattedTime);
  
    this.setState((prevState) => ({
      requiredInputFieldsValue: {
        ...prevState.requiredInputFieldsValue,
          [inputId]: formattedTime,
      },
    }));
  
    const inputData = { id: inputId, user_input: formattedTime };
    this.saveData(inputData);
    this.handleIsCalculateCost();
    return formattedTime;
    }
  };


  addHoursToTime(timeString: string, hoursToAdd: number) {
    const [hours, minutes] = timeString.split(':').map(num => parseInt(num, 10));
    const date = new Date();
    
    date.setHours(hours);
    date.setMinutes(minutes);
    
    date.setHours(date.getHours() + hoursToAdd);
    
    const newHours = String(date.getHours()).padStart(2, '0'); 
    const newMinutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${newHours}:${newMinutes}`;
  }
  

  waitForEventTimePopulation = (inputName: string, inputId: string) => {
    // istanbul ignore next
    let intervalId = setInterval(() => {
      if (this.state.isEventTimePopulated) {
        if (intervalId) clearInterval(intervalId);
        const selectedSubCategory =  this.state.customForm.attributes && this.state.customForm.attributes.sub_category_name
        const startEventInputValue = this.state.initialValueField[inputId];
        const endEventInputValue = this.addHoursToTime(startEventInputValue, selectedSubCategory === 'Full-Day' ? 9 : selectedSubCategory === 'Half-Day' ? 5 : 0 );
        if (inputName === 'Event Start Time' && startEventInputValue && endEventInputValue) {
          this.setState({
            eventDetails: {
              ...this.state.eventDetails,
              startTime: startEventInputValue,
              endTime: endEventInputValue,
            },
            isEventTimePopulated: false,
            timeValid: { ...this.state.timeValid, isEndTimeInvalid: false }
          });
        }
      }
    }, 100);

    return intervalId;
  };


  saveEventTime = (event: any, name: string, inputId: string, setFieldValue: any) => {
    const time = this.handleTimeChange(event, setFieldValue, inputId);
    const inputName = this.handleEventTime(name) as string;

    const intervalId = this.waitForEventTimePopulation(inputName, inputId);

    if (inputName === 'Event End Time') {
      if (intervalId) clearInterval(intervalId);
      this.setState({
        eventDetails: { ...this.state.eventDetails, },
        isEventTimePopulated: false,
        timeValid: { ...this.state.timeValid, isEndTimeInvalid: false },
        timeError: false
      });
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  };

  checkEventTimeValidation = (name: string) => {
    return name === 'Event End Time' && !this.state.isEventStartTime && !(this.state.customForm.attributes.sub_category_name?.includes("Multi-Day") || this.state.customForm.attributes.is_bespoke);
  }

  sanitizeTimeInputField = (numericInput:string) => {
    if (numericInput.length > 0) {
      let firstH = numericInput[0];
    if (firstH > '2') { numericInput = '0' + firstH + numericInput.substring(1);
      }
    }

    if (numericInput.length > 1) {
      let firstH = numericInput[0];
      let secondH = numericInput[1];

    if (firstH === '2' && secondH > '3') { numericInput = firstH + '3' + numericInput.substring(2); 
      } else if (firstH !== '2' && secondH > '9') {
        numericInput = firstH + '9' + numericInput.substring(2); 
      }
    }

    if (numericInput.length > 2) {
      let firstM = numericInput[2];
      if (firstM > '5') { numericInput = numericInput.substring(0, 2) + '5' + numericInput.substring(3);
      }
    }

    if (numericInput.length > 3) {
    let secondM = numericInput[3];
    if (secondM > '9') { numericInput = numericInput.substring(0, 3) + '9'; 
      }
    }
    return numericInput.length > 4 ? numericInput.substring(0, 4) : numericInput;

  }

  updateErrorOfTimeField = (name: string, time: string) => {
    
    const updatedErrorField = (bool: boolean) => {
      if (name === "Event Start Time") {
        let newTime = { ...this.state.timeValid, isStartTimeInvalid: bool }
        this.setState({ timeValid: newTime });
      } else if (name === "Event End Time") {
        let newTime = { ...this.state.timeValid, isEndTimeInvalid: bool }
        this.setState({ timeValid: newTime });
      }
    }

    const validTimePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
    let isValid = validTimePattern.test(time);
    if (!isValid) {
      updatedErrorField(true);
    } else {
     updatedErrorField(false);
    }
    
  };

   /*istanbul ignore next */
  handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    const name = input.name;
    
    const cursorPosition:any = input.selectionStart;
    
    const beforeCursor:any = input.value.slice(0, cursorPosition);
    
    let value = input.value.replace(/[^0-9:]/g, "");
    
    let parts = value.split(":");
    
    if (parts.length > 1) {
        let hours = parts[0].slice(0, 2);
        let minutes = parts[1].slice(0, 2);
        
        if (cursorPosition <= hours.length) {
            if (parts[0].length > 2) {
                minutes = parts[0].slice(2) + minutes;
                minutes = minutes.slice(0, 2);
            }
        }
        else if (cursorPosition > hours.length + 1) {
            if (parts[1].length > 2) {
                minutes = minutes.slice(0, 2);
            }
        }
        
        value = `${hours}:${minutes}`;
    } else {
        if (value.length > 2) {
            const hours = value.slice(0, 2);
            const minutes = value.slice(2, 4);
            value = minutes ? `${hours}:${minutes}` : `${hours}`;
        }
    }
    
    input.value = value;
    
    let newPosition = cursorPosition;
    if (beforeCursor.length === 2 && !beforeCursor.includes(":")) {
        newPosition++;
    }
    if (value.length < cursorPosition) {
        newPosition = value.length;
    }
    
    setTimeout(() => {
        input.setSelectionRange(newPosition, newPosition);
    }, 0);
    
    this.updateErrorOfTimeField(name, value);
};



/*istanbul ignore next */
  handleTimeInput = (event:any, name:any, setFieldValue:any) => {
    let rawInput = typeof event === "string" ? event : "";
    let numericInput = rawInput.replace(/\D/g, ''); 
    if (numericInput.length > 4) {
      numericInput = numericInput.substring(0, 4); 
    }
  
    let formattedTime = numericInput;
    if (numericInput.length > 2) {
      formattedTime = `${numericInput.substring(0, 2)}:${numericInput.substring(2, 4)}`;
    }
    setFieldValue(name, formattedTime);
  };
  



  handleCalculateCostApiCall = () => {
      this.calculatedAmount();
  }

  handleApproveResFn = (responseJson: any) => {
    if(responseJson.message === 'This inquiry is already approved' || responseJson.message === 'This inquiry is already rejected'){
      this.setState({openDialogName:'already approved',errorMessage:responseJson.message})
    }
    if (responseJson.message == "Success") {
      this.setState({ openDialogName: "submit successfully ClientAdmin" })
    }
  }

  handleSubbmmitResFn = (responseJson: any) => {
    if (responseJson?.message === "Inquiry successfully submitted") {
      const userRole = localStorage.getItem("role")
      this.handleSubEnquiryResFn(userRole, responseJson);
    } else if (responseJson?.message) {
      this.setState({ openDialogName: "Error 2", errorMessage: responseJson.message })
    } else {
      this.setState({ openDialogName: "Error 2", errorMessage: responseJson?.error ?? configJSON.unExpectedError })
    }
  }

  handledraftResFn = async (responseJson: ResponseJson) => {
    if (responseJson.message == "Inquiry successfully draft" && this.state.openDialogName === "Draft") {
      localStorage.removeItem("enqiry");
      localStorage.setItem("enqiry", "draft");
      this.props.navigation.navigate("DraftEnquiries")
    }else if(responseJson.message === "Inquiry successfully draft" && this.state.submissionType === "continueReview"){
      this.calculatedAmount();
      this.openRBSPopup()
      this.setState({ timeError: false })
    }
    else if (responseJson.error === "Event duration must be less than 9 hours") {
        this.setState({ openDialogName: "Error 2", errorMessage: "Event duration can't be more than 9 hours" })
        this.setState({ timeError: true })
      }
    else if (responseJson?.inquiry) {
      this.setState({ draftEnquiryResponse: { lf_admin_approval_required: responseJson.inquiry.data.attributes.lf_admin_approval_required } });
      this.props.navigation.navigate("DraftEnquiries")
    }else {
      this.setState({ openDialogName: "Error 2", errorMessage: responseJson?.error ?? configJSON.unExpectedError })
    }
  }


  handleRejectResFn = (responseJson: any) => {
    if(responseJson.message === 'This inquiry is already approved' || responseJson.message === 'This inquiry is already rejected'){
      this.setState({openDialogName:'already approved',errorMessage:responseJson.message})
    }
    if (responseJson.message == "Success") {
      this.setState({ openDialogName: "", rejectState: true })
    }
  }

  async handleRestAPIResponseMessage(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCategoriesApiCallId) {
      this.handleCategoriesApiResponse(responseJson);
      this.setState({ categoryList: responseJson, isLoading: false, currentPage: 1 })
    }
    else if (apiRequestCallId === this.getAddonApiId) {
      this.handleManageAdditionalServicesResponse(message)
    }
    // else if (apiRequestCallId === this.postalCodeKey) {
    //   this.postalCodeOptions(responseJson)
    // }
    else if (apiRequestCallId === this.getMessageApiCallId) {
      this.handleGetMessageResponse(responseJson);
    }
    else if (responseJson?.inquiry !== undefined && responseJson?.inquiry.data) {
      this.handleInquiryResponse(apiRequestCallId, responseJson);
    }
    else if(responseJson?.message === "Inquiry not found" && apiRequestCallId === this.getInquiryId && this.state.searchParam  ){      
      this.setState({ openDialogName: "NotFound" });
    }
    else if (responseJson?.message === "Inquiry not found" && apiRequestCallId === this.getInquiryId && (this.state.enquiryDetails.id || this.props.navigation.getParam("id"))) {
      this.setState({ openDialogName: "" }, () => {
        this.props.navigation.navigate("NotAuthorized");
      });
    }
    else if (apiRequestCallId === this.calculatedAmountAPiCallId) {
      if (responseJson.errors) {
        this.handleErrorMessage(responseJson)
      }
    }
    else if (apiRequestCallId === this.getLocationAPICallId) {
      this.handleCompanyLocations(responseJson)
    }

  }
  handleErrorMessage = (resp: any) => {
    if (Array.isArray(resp.errors) && resp.errors.length > 0) {
      this.setState({
        warningMessageBody: resp.errors[0]
      })
      let errorMessage = resp.errors[0].error.toLowerCase()
      if (errorMessage.includes("please contact your account manager")) {
        this.setState({ warningMessage: resp.errors[0].error, meetWithExpertDialog: true })
      }
    }
  }
  handleCategoriesApiResponse(responseJson: any) {
    
    if (responseJson?.data !== undefined && responseJson?.data) {
      const filteredCategories = responseJson.data.filter((categoryList: any) => {
        return (
          categoryList.attributes.id !== this.state.categoryId &&
          categoryList.attributes.id !== this.state.categoryBaseID
        );
      });
      const packageList = filteredCategories.map((categoryList: any) => {
        const subCategories = categoryList.attributes.sub_categories?.data?.map(
          (subCategory: any) => subCategory.attributes.name
        ) || [];

        return {
          id: categoryList.id,
          title: categoryList.attributes.name,
          subCategories: subCategories,
          sub_category_id: categoryList.attributes.sub_categories.data.map((subcat: any) => subcat.id),
        };
      });

      this.setState({ packageSlectedList: packageList }, () => {
        this.filterAdditionalSubCategory()
      }
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  handleManageAdditionalServicesResponse = (message:Message) => { 
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(responseJson.extra_services_detail){
      this.setState({manageAdditionalServiceDetails:responseJson},() => {
        this.getInquiry()
      })
    }
  }

  trimString(givenString: string, maxLength: number) {
    if (givenString) {
      if (givenString.length > maxLength) {
        return givenString.slice(0, maxLength) + '...';
      }
      return givenString;
    }
  };

  handlePopulateEndTime = (responseJson: ResponseJson) => {
    if (this.state.isEventDateChanged) {
      const { base_service_detail, extra_services_detail } = responseJson.inquiry.data.attributes;
      const fields = this.extractFields(base_service_detail, extra_services_detail);
      const fieldsResponse = this.constructResponse(fields, responseJson.inquiry.data.attributes.note)
      this.setState({ initialValueField: fieldsResponse});
    }
  }

  handleInquiryResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getInquiryId) {
      this.handleGetInquiryResponse(responseJson);
      if(this.state.initialLoad){
        this.viewInquiry(responseJson.inquiry.data)
      }
    }
    else if (apiRequestCallId == this.changeEnquiresAPICallID) {
      this.props.navigation.navigate("CustomForm", { id: responseJson.inquiry.data.attributes.id })
      this.setState((prevState) => ({
        openDialogName: "",
        initializePackageSelectedItem: true,
        customForm: {
          ...prevState.customForm,
          attributes: {
            ...prevState.customForm.attributes,
            sub_category_name: responseJson.inquiry.data.attributes.sub_category_name
          }
        }
      }));

    }
    else if (apiRequestCallId == this.calculatedAmountAPiCallId) {
      this.setState({ customForm: responseJson.inquiry.data, eventDetails: { ...this.state.eventDetails, startTime: responseJson.inquiry.data.attributes.event_time } });
      this.handlePopulateEndTime(responseJson)
      this.handleDefaultCovergesNew(responseJson)
    }
  }

  setDefaultInputValues = (data: any, input_name?: string, value?: any) => {
    let requiredInformation = Array.isArray(data) && data.map((input: any) => {
      if (input.attributes.input_field.name === input_name) {
        const updatedInput = {
          ...input,
          attributes: {
            ...input.attributes,
            user_input: value
          }
        };
       this.saveData({ id: updatedInput.id, user_input: value })
        return updatedInput;
      }
      return input;
    });
    return requiredInformation;
  };
  updateResponseJsonWithModified(modifiedReq: any, responseJson: any) {
    let inputValues = responseJson.inquiry.data.attributes.base_service_detail.data.attributes.input_values.data;
    modifiedReq.forEach((modifiedInput: any) => {
      let matchingInput = inputValues.find((input: any) => input.id === modifiedInput.id);
      if (matchingInput) {
        matchingInput.attributes = {
          ...matchingInput.attributes,
          ...modifiedInput.attributes
        };
      }
    });
    return responseJson;
  }

  inputFieldsUpdateForFormik = (fields: any, responseJson: any, data: any, fieldsUpdateFunction: any, input_name?: any, value?: any) => {
    const fieldsResponseUpdated = this.constructResponse(fields, responseJson.inquiry.data.attributes.note)
    this.setState({ initialValueField: fieldsResponseUpdated });
  }

  handleGetInquiryResponse(responseJson: ResponseJson) {
    if (this.state.enquiryDetails.isEnquiry) {
      this.getCategories();
    }
    const baseServiceId = responseJson.inquiry.data.attributes.base_service_id;
    this.setState({
      customForm: responseJson.inquiry.data,
      categoryId: responseJson.inquiry.data.attributes.service_id,
      categoryBaseID: baseServiceId,
      base_service_detail: responseJson.inquiry.data.attributes.base_service_detail?.data?.attributes || {},
      is_service: responseJson.inquiry.data.attributes.is_service,
      company_id: responseJson.inquiry.data.attributes.company_id,
    }, () => {
      if (this.state.companyLocations.length < 1) {
        this.getCompanyLocations()
      }
    }
    );

    const { base_service_detail, extra_services_detail } = responseJson.inquiry.data.attributes
    const fields = this.extractFields(base_service_detail, extra_services_detail);

    if (responseJson.inquiry.data.attributes.is_previous) {
      this.setPreviousEventDate(responseJson.inquiry.data.attributes.event_date)
    }

    if (responseJson.inquiry.data.attributes.note) {
      this.setCustomFormProjectDescription(responseJson.inquiry.data.attributes.note);
    }
    
    const groupBySection = this.handleGroupBySection(base_service_detail);
    const stagesofContent = groupBySection["required_information"].find((obj: InputValuesData) => obj.attributes.input_field.name === "How many stages of content do you want Audio/Video/AV support?");
    const modifiedRequiredInformation = this.setDefaultInputValues(groupBySection['required_information'], configJSON.stagesOfContent, stagesofContent?.attributes.user_input === null ? '1' : stagesofContent?.attributes.user_input);
    const updatedResponseJson = this.updateResponseJsonWithModified(modifiedRequiredInformation, responseJson);
    this.inputFieldsUpdateForFormik(fields, updatedResponseJson, groupBySection['required_information'], this.setDefaultInputValues, configJSON.stagesOfContent,  stagesofContent?.attributes.user_input === null ? '1' : stagesofContent?.attributes.user_input);
    const eventStartDetails = groupBySection["required_information"].find((obj: InputValuesData) => obj.attributes.input_field.name === "Event Start Time");
    if (eventStartDetails?.attributes.user_input) {
      this.setState({ isEventStartTime: true });
    };

    const groupByCoverage = responseJson.inquiry.data.attributes.default_coverages.reduce((group: GroupByCoverage, product: DefaultCoverage) => {
      const { category } = product;
      group[category] = group[category] ?? [];
      group[category].push(product);
      return group;
    }, {});

    const addonCoverage = this.groupAddonCoverage(extra_services_detail.data);

    const defaultAdditionaPackage = extra_services_detail.data.map((group: ExtraServiceData) => group.attributes.service_id.toString());

    this.setState({
      groupByCoverage: groupByCoverage,
      addonCoverage: addonCoverage,
      groupByRequiredInformation: modifiedRequiredInformation,
      groupByAddons: groupBySection["addon"],
      groupByServices: groupBySection["services"],
      groupByAdditionalAddon: extra_services_detail.data,
      groupBySection: groupBySection,
      isBespokeRequest: this.state.customForm.attributes.sub_category_name == "Bespoke Request",
      requiredInputFieldsValue: this.constructInitialRequiredInfo(groupBySection["required_information"])
    })

    if (this.state.initializePackageSelectedItem) {
      this.setState({
        packageSelectedItem: defaultAdditionaPackage,
        initializePackageSelectedItem: false
      }, () => {this.getAddons()})
    }
  }

  handleDefaultCovergesNew = (responseJson:any) => {
    const {  extra_services_detail } = responseJson.inquiry.data.attributes
    this.setState({
      additionalCoverages1: extra_services_detail.data,
    })
    const addonCoverage = this.groupAddonCoverage(extra_services_detail.data);
    this.setState({
      addonCoverage: addonCoverage,
    })
  }

  extractFields(baseServiceDetail: BaseServiceDetail, extraServicesDetail: ExtraServicesDetail) {
    let fields = [];
    if (extraServicesDetail.data.length > 0) {
      const AddonListArray = extraServicesDetail.data.map((inputList: ExtraServiceData) => inputList.attributes.input_values.data).flat()
      fields = [...baseServiceDetail?.data?.attributes?.input_values?.data, ...AddonListArray]
    } else {
      fields = baseServiceDetail?.data?.attributes?.input_values?.data
    }
    return fields;
  }

  constructResponse(fields: InputValuesData[], note: string) {
    let fieldsResponse: FieldsResponse = {}
    fields.forEach((item: InputValuesData) => { fieldsResponse[String(item.id)] = item.attributes.user_input });
    fieldsResponse["note"] = note;
    return fieldsResponse
  }

  handleGroupBySection = (base_service_detail: BaseServiceDetail) => {
    return base_service_detail.data.attributes.input_values.data.reduce((group: GroupBySection, inputDataSection: InputValuesData) => {
      const { section } = inputDataSection?.attributes?.input_field;
      group[section] = group[section] ?? [];
      group[section].push(inputDataSection);
      return group;
    }, {});
  }

  handleCompanyLocations = (responseJson: any) => {
    if (!responseJson.error) {
      const locations = responseJson.company_locations?.map((item: any) => {
        return item.venue_location;
      });
      const flattenedLocations = [].concat(...locations);
      this.setState({ companyLocations: flattenedLocations }, () => {
        if(this.state.companyLocations.length === 1){
          this.setDefaultInputValues(this.state.base_service_detail?.input_values?.data, 'Location / Venue', this.state.companyLocations[0])
        }
      }
        );
    } else {
      return
    }
  }
  handleLocationSelection = (e: any) => {
    this.setState({ eventDetails: { ...this.state.eventDetails, location: e.target.value }, locationCheck: true })
  }
  setPreviousEventDate(eventDate: string) {
    const event_Date = new Date(eventDate)
    this.setState({ currentEventData: event_Date })
  }

  groupAddonCoverage(extraServicesData: ExtraServiceData[]) {
    return extraServicesData.reduce((group: AddonCoverage, service: ExtraServiceData) => {
      const serviceName = service.attributes.service_name;
      const defaultCoveragesData = Array.isArray(service.attributes.default_coverages)
      ? service.attributes.default_coverages 
      : service.attributes.default_coverages?.data || [];

      if (defaultCoveragesData) {
        defaultCoveragesData.forEach((coverage: DefaultCoverage) => {
          const { category, title } = coverage.attributes || coverage;
          group[serviceName] = group[serviceName] || {};
          group[serviceName][category] = group[serviceName][category] || [];
          group[serviceName][category].push(title);
        });
      }
      return group;
    }, {});
  }

  constructInitialRequiredInfo(groupByRequiredInformation: InputValuesData[]) {
    let initialRequiredInfo = {};
    groupByRequiredInformation.forEach((info: InputValuesData) => {
      initialRequiredInfo = { ...initialRequiredInfo, [info.attributes.id]: info.attributes.user_input };
    });
    return initialRequiredInfo;
  }

  filterAdditionalSubCategory() {
    const { packageSelectedItem, packageSlectedList, additionalServicesSubCategories = [], customForm } = this.state;
    const selectedItems = Array.isArray(packageSelectedItem) ? packageSelectedItem : [];
    const previousData = customForm.attributes?.extra_services_detail.data

    const updatedSubCategories = selectedItems.map(itemId => {
      const existingItem = additionalServicesSubCategories.find(item => item.id === itemId);
      const packageItem = packageSlectedList.find(item => item.id === itemId);
      const filteredSubCategories = packageItem?.subCategories?.filter((subCategory: any) => !subCategory.includes('Multi-Day'));

      if (!packageItem || !packageItem.subCategories?.length) return null;
      const previousSubCatSelect = previousData?.find((item:any) => item.attributes.service_id == itemId)
      let previousSubCat = null

      if(previousSubCatSelect?.attributes?.additional_service_sub_categories?.data){
        const value = previousSubCatSelect.attributes.additional_service_sub_categories.data[0].attributes.name
        previousSubCat = value
      }
      if(previousSubCat === null){
        previousSubCat = existingItem?.selectedSubCat || (filteredSubCategories.length === 1 ? filteredSubCategories[0] : null)
      }
      
      return {
        ...packageItem,
        subCategories: filteredSubCategories,
        selectedSubCat: previousSubCat,
      };
    });
    this.setState(prevState => ({
      ...prevState,
      additionalServicesSubCategories: updatedSubCategories.filter(Boolean),
    }),() => {
      this.handleUpdateAdditionalServiceCost()
    });
    return updatedSubCategories;
  }
  
  handleUpdateAdditionalServiceCost = () => {
    interface AdditionsubType {
      id: string;
      selectedSubCat: string;
      subCategories: string[];
      sub_category_id: string[];
      title: string;
    }
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };
    const selectedSubCategories: AdditionsubType[] = this.state.additionalServicesSubCategories
    const manageAddService = this.state.manageAdditionalServiceDetails?.extra_services_detail?.data ?? []

    const requestBody = selectedSubCategories.map((subCat) => {
      const singleAddService = manageAddService.find((service) => service.attributes.service_id === Number(subCat.id))
      if (subCat.selectedSubCat?.length > 0 && singleAddService) {
        const indexofSeletedValue = subCat.subCategories.findIndex(item => item === subCat.selectedSubCat)
        const subCatID = subCat.sub_category_id[indexofSeletedValue]
        return { "additional_service_id": singleAddService.attributes.id, "sub_category_id": Number(subCatID) }
      }
      return undefined
    })
    const purifyRequestBody = requestBody.filter((item) => Boolean(item))

    if (purifyRequestBody.length === 0) return;
    const body = {
      "inquiry_id": Number(this.props.navigation.getParam("id")),
      "sub_categories": purifyRequestBody
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAdditionalServiceCostID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateAdditionalServiceCost
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  
  handleSubCatChange = (event: any, additionalPackageId: any) => {
    const { additionalServicesSubCategories } = this.state;
    const updatedSubCategories = additionalServicesSubCategories.map((packageItem) => {
      if (packageItem.id === additionalPackageId) {
        return {
          ...packageItem,
          selectedSubCat: event.target.value 
        };
      }
      return packageItem;
    });

    this.setState({
      additionalServicesSubCategories: updatedSubCategories,
    },() =>{
      this.handleUpdateAdditionalServiceCost()
    });
  }

  handleGetMessageResponse(responseJson: any) {
  }



  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionResponseToken)
    );
    this.send(msg);
  };
  goToHome = () => {
    this.props.navigation.navigate("LandingPage");
  }
  handleModalClose = () => {
    if (this.state.customForm.attributes.status === "draft") {
      this.setState({ openDialogName: "" })
    } else {
      this.goToEnquiryCustom()
    }
  }
  goToEnquiryCustom = () => {
    localStorage.removeItem("enqiry");
    localStorage.setItem("enqiry", "");
    const userRole = localStorage.getItem("role");
    const {customForm} = this.state;
    const baseService = customForm?.attributes?.base_service_detail?.data?.attributes?.service_name;
    if (baseService === "Bespoke Request") {
      return this.props.navigation.navigate("PendingEnquiries");
    }

    if (userRole === "ClientAdmin") {
      return this.props.navigation.navigate("ApprovedEnquiries");
    }
    
    const customVal = parseFloat(customForm?.attributes?.cost_summery?.sub_total?.replace(/,/g, '')) || 0;
    const approvalLimit = parseFloat(customForm?.attributes?.auto_approval_limit) || 0;
    const nextScreen = customVal < approvalLimit ? "ApprovedEnquiries" : "PendingEnquiries";
    this.props.navigation.navigate(nextScreen);
  }
  changeEnquiriesClick = () => {
    this.changeEnquiries();
  }

  isAudioOrVisualService = () => {
    return this.state.base_service_detail?.service_name?.includes('Audio Packages') || this.state.base_service_detail?.service_name?.includes('Visual Packages');
  };

  isAddingConflictingPackage = (innerText: string) => {
    const { packageSelectedItem, additionalServicesSubCategories } = this.state;
    if (innerText === 'Audio Packages') {
      return Array.isArray(packageSelectedItem) && packageSelectedItem.some(itemId => {
        const service = additionalServicesSubCategories.find((item: any) => item.id === itemId);
        return service && service.title === 'Visual Packages';
      });
    }

    if (innerText === 'Visual Packages') {
      return Array.isArray(packageSelectedItem) && packageSelectedItem.some(itemId => {
        const service = additionalServicesSubCategories.find((item: any) => item.id === itemId);
        return service && service.title === 'Audio Packages';
      });
    }

    return false;
  };


  isVisualPackageSelected = (innerText: any) => {
    const { packageSelectedItem, additionalServicesSubCategories } = this.state;
    if (innerText === 'AV Packages') {
      return Array.isArray(packageSelectedItem) && packageSelectedItem.some(itemId => {
        const service = additionalServicesSubCategories.find((item: any) => item.id === itemId);
        return service && service.title === 'Visual Packages';
      });
    }
  }

  isAudioPackageSelected = (innerText: any) => {
    const { packageSelectedItem, additionalServicesSubCategories } = this.state;

    if (innerText === 'AV Packages') {
      return Array.isArray(packageSelectedItem) && packageSelectedItem.some(itemId => {
        const service = additionalServicesSubCategories.find((item: any) => item.id === itemId);
        return service && service.title === 'Audio Packages'
      });
    }
  }


  handleInfoMessage = (innerText: string) => { 
    const {isAVSelected } = this.state;
    let infoText = '';
    let buttonText = '';
    const isAudioOrVisualBase = this.isAudioOrVisualService();
    const isAddingCoflictingPackage = this.isAddingConflictingPackage(innerText);
    const conflictingPacks = ['Visual Packages', 'Audio Packages', 'AV Packages'];
    
    if(!isAVSelected && !isAudioOrVisualBase && (innerText === "AV Packages") && this.isVisualPackageSelected(innerText)){
      infoText = configJSON.VisualWithAVDisabledInfoMessage;
      this.setState({isInfoMessage: true})
    }else if(!isAVSelected && !isAudioOrVisualBase && (innerText === "AV Packages") && this.isAudioPackageSelected(innerText)){
      infoText = configJSON.AudioWithAVDisabledInfoMessage;
      this.setState({isInfoMessage: true})
    }else if (isAudioOrVisualBase && conflictingPacks.some((pack) => pack === innerText) || isAddingCoflictingPackage){
      infoText = configJSON.AudioMainServMessage;
      buttonText = 'Make Changes'
      this.setState({isInfoMessage: true, selectedAdditionalSubcategory: innerText})
    } else if (isAVSelected && !isAudioOrVisualBase && conflictingPacks.some((pack) => pack === innerText)) {
      infoText = configJSON.AVInfoMessage;
      buttonText = 'Make Changes'
      this.setState({isInfoMessage: true, selectedAdditionalSubcategory: innerText})
    } else {
        this.handleInfoMessageClose()
    }

    this.setState({
      infoMessage: {
        infoText: infoText,
        buttonText: buttonText
      }
    });
  }

  getAudioVideoIds = () => {
    const { additionalServicesSubCategories } = this.state
    return additionalServicesSubCategories
    .filter(service => service.title === 'Audio Packages' || service.title === 'Visual Packages' )
    .map(service => service.id);
  }

  getAudioVideoAndAvIds = () => {
    const { additionalServicesSubCategories } = this.state
    return additionalServicesSubCategories
    .filter(service => service.title === 'Audio Packages' || service.title === 'Visual Packages' || service.title === 'AV Packages')
    .map(service => service.id);
  }

  handleMessageForAudioOrVideoBase = (innerText: string) => {
    const isAudioOrVideoBase = this.isAudioOrVisualService();
    if(!isAudioOrVideoBase) return
    if (innerText === 'Audio Packages' || innerText === 'Visual Packages' || innerText === 'AV Packages') {
      this.handleInfoMessage(innerText);
      return;
    }
  }

  handleConflictingPackageMessageInfo = (innerText: string) => {
    if (this.isAddingConflictingPackage(innerText)) {
      this.handleInfoMessage(innerText);
      return;
    }
  }

  handlePackageSelection = (event: any) => {
    let { value } = event.target;
    let { innerText } = event.currentTarget;
    const { isAVSelected } = this.state;

    let updatedPackageSelectedItem:any;

    const audioVideoIds = this.getAudioVideoIds()

    const audioVideoAndAvIds = this.getAudioVideoAndAvIds()

    this.handleMessageForAudioOrVideoBase(innerText)

    this.handleConflictingPackageMessageInfo(innerText)

    if (innerText === 'AV Packages' && audioVideoAndAvIds.length <=0) {
      updatedPackageSelectedItem = Array.isArray(value) && value.filter(
        (itemId: any) => !audioVideoIds.includes(itemId)
      );
    } else if(!isAVSelected && innerText === 'AV Packages' && audioVideoAndAvIds){
      this.handleInfoMessage(innerText);
      return;
    } else {
      if (isAVSelected && (innerText === 'Audio Packages' || innerText === 'Visual Packages')) {
        this.handleInfoMessage(innerText);
        return;
      }

      updatedPackageSelectedItem = value;
    }
    this.handleInfoMessage(innerText)

    this.setState({ packageSelectedItem: updatedPackageSelectedItem }, () => {
      if (!updatedPackageSelectedItem?.length) {
        this.resetInfoMessage();
        return;
      }
    
      const updatedSubCategories: any = this.filterAdditionalSubCategory();
      if (!updatedSubCategories?.length || this.hasEmptySubCategories(updatedSubCategories)) {
        this.showInfoMessage(`Subcategories not found for '${innerText}' service`);
        return;
      }
    
      this.handleInfoMessageClose();
      this.setState({ additionalServicesSubCategories: updatedSubCategories });
    });
    
    this.getAddons();
  }

  resetInfoMessage() {
    this.setState({
      isInfoMessage: false,
      infoMessage: {
        infoText: "",
        buttonText: "",
      },
      additionalServicesSubCategories: [],
    });
  }
  
  showInfoMessage(infoText: string) {
    this.setState({
      infoMessage: {
        infoText,
        buttonText: "",
      },
      isInfoMessage: true,
      packageSelectedItem: [],
    });
  }
  
  hasEmptySubCategories(subCategories: any[]): boolean {
    return subCategories.some((item: any) => !item?.subCategories?.length  || item == null);
  }

  handleInfoMessageClose = () => {
    this.setState({
      isInfoMessage: false,
      infoMessage: {
        infoText: '',
        buttonText: ''
      }
    })
  }

  getCategories = () => {
    if (!this.state.token) {
      return;
    }
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAddons = () => {
    if (!this.state.token) {
      return;
    }
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAddonApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.manageAddistionalService
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    const httpBody = {
      inquiry_id: this.props.navigation.getParam("id"),
      service_ids: this.state.packageSelectedItem,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeAddons = () => {
    if (!this.state.token) {
      return;
    }
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };

    const httpBody = {
      inquiry_id: this.props.navigation.getParam("id"),
      service_ids: this.state.packageSelectedItem,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeAddonApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.manageAddistionalService
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeEnquiries = () => {

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeEnquiresAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/change_inquiry_sub_category?inquiry_id=${this.props.navigation.getParam("id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMessage = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/change_inquiry_sub_category?inquiry_id=${this.props.navigation.getParam("id")}&only_message=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInquiry = async (id: any = null) => {

    if (!this.state.token) {
      return;
    }

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInquiryId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInquiryEndpoint + `/${this.state.enquiryDetails.isEnquiry ? this.state.enquiryDetails.id : id || this.props.navigation.getParam("id")}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getInquiryFilter = async (filter: any) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/inquiries?filter_by=${filter}&status=${this.state.status}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  viewInquiry = (inquiry: any) => {
    const currentUrl = window.location.pathname;
    const newUrl = `${currentUrl}?id=${inquiry.id}`;
  
    if (currentUrl.includes("Enquiries")) {
      window.history.pushState(null, "", newUrl);
    
    this.setState(
      { 
        openDialogName: "ViewEnquiryStatus", 
        enquiryDetails: { isEnquiry: true, id: inquiry.id }, 
        isLoading: false, 
        EnquiryData: inquiry ,
        company_id: inquiry.attributes.company_id
      },
    )}
  
    this.handleDownload(inquiry);
  };
  



  viewReq = (reqID: string) => {
    this.setState({ enquiryDetails: { isEnquiry: true, id: reqID } })
    this.handleDownload(reqID);
  }

  saveData = (userVal: any) => {
    this.setSumitCustomForm([userVal].flat())
  }

  onCancel = () => {

    if (this.state.openDialogName == "upload") {
      this.setState({ openDialogName: "" }, () => this.getInquiry());
    }
    else if (this.state.openDialogName == "ViewEnquiryStatus") {
      this.props.navigation.navigate("Enquiries")
      this.setState({ openDialogName: "" })
      if (!this.state.categoryList?.inquiries?.data) {
        this.setState({ isLoading: true })
      }
    }
    else {
      this.setState({
        openDialogName: "", calendarType: "calendarIcon"
      });
    }
  }

  closeEnquiryPopup = () => {
    this.props.navigation.navigate("Enquiries")
    this.setState({ openDialogName: "" })
  }

  closeCustomFormPopup = () => {
    this.setState({ openDialogName: "" });
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getFileName = (fileName: any) => fileName.length > 25 ? `${fileName.slice(0, 30)}...` : fileName;

  getInputName = (inputName: string) => inputName.length > 25 ? `${inputName.slice(0, 28)}...` : inputName;

  downloadFile = async (fileName: string, urlLink: string) => {

    const response = await fetch(urlLink);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);

  }



  onCancelCalendar = () => {
    const popupName = localStorage.getItem("role")
    this.setState({ openDialogName: popupName || "", calendarType: "calendarIcon" }, () => this.getInquiry());
  }

  onEnquiryCancel = () => {
    this.setState({ openDialogName: "", rejectState: false, isLoading: true }, () => {
      this.getcategoryList(this.state.category);
      this.props.navigation.navigate("Enquiries")
    });
  }

  onPopCancel = () => {
    const { daysCoverageLastValue } = this.state;
    const fullDayAllowedCoverage = ['1', '2', '3'];

    if (this.state.warningMessage !== 'No need to change package duration') {
      if (this.state.customForm.attributes.sub_category_name.includes("Half-Day")) {
        this.setState({conditionalDaysCoverage: '0.5'})
      } else if ((this.state.customForm.attributes.sub_category_name.includes('Full-Day'))&& fullDayAllowedCoverage.includes(daysCoverageLastValue)) {
        this.setState({conditionalDaysCoverage: daysCoverageLastValue})
      }else if((this.state.customForm.attributes.sub_category_name.includes('Full-Day') || this.state.customForm.attributes.sub_category_name?.includes("AV"))){
        this.setState({conditionalDaysCoverage: '1'})
      }
    }
   
    this.setState(
      {
        openDialogName: "",
      },
      () => {
        this.getInquiry();
      }
    );
  };

  onAddBtnClicked = () => {
    this.setState({
      openDialogName: "upload",
      errorMessagess: "",
      duplicateUploadedFiles: [], 
      maxUploadLimitReached: false,
    });
  }

  handleEventDurationValidation() {
    let errorMessage = ''
    const startTime = parseInt(this.state.eventDetails.startTime);
    const endTime = parseInt(this.state.eventDetails.endTime);
    const { sub_category_name } = this.state.customForm.attributes;
    const diff = endTime - startTime;
    if (diff > 5 && sub_category_name?.includes('Half-Day')) {
      errorMessage = "Event duration can't be more than 5 hours";
    } else if (diff > 9 && sub_category_name?.includes('Full-Day')) {
      errorMessage = "Event duration can't be more than 9 hours";
    }
    return errorMessage;
    }

  onReviewBtnClicked = () => {
    this.setState({ continueToReview: true });
    let isPreviousEventDateLessThenCurrentDate = false;
    let isPreviousEventDateEqualCurrentDate = false;
    if (this.state.customForm.attributes.is_previous) {
      const previousEventDate = moment(this.state.customForm.attributes.parent_inquiry_event_date)
      const currentEventDate = moment(this.state.currentEventData);
      isPreviousEventDateLessThenCurrentDate = currentEventDate.isBefore(moment().startOf('day'));
      isPreviousEventDateEqualCurrentDate = currentEventDate.isSame(previousEventDate);
      this.setState({ previousEventDateEqualCurrentDate: isPreviousEventDateEqualCurrentDate, previousEventDateLessThenCurrentDate: isPreviousEventDateLessThenCurrentDate })
    }
    if (this.checkRequiredFields() || this.state.locationCheck || isPreviousEventDateEqualCurrentDate || isPreviousEventDateLessThenCurrentDate) {
      this.setState({ openDialogName: "RequiredFieldValidation" });
      return;
    }

    const durationErrorMessage = this.handleEventDurationValidation();
    if (durationErrorMessage) {
      this.setState({ openDialogName: "ErrorForPackages", errorMessage:durationErrorMessage})
      this.setState({ eventDetails: { ...this.state.eventDetails, durationError: durationErrorMessage } })
    } else {
      this.savetoDraft();
    }


  }
  onSubmitButtonClick = () => {
    this.submitForm();
    this.setState({ openDialogName: "" });
  }
  onApproveButtonClick = () => {
    this.approveForm();
    this.setState({ openDialogName: "" });
  }

  handleReset = (setFieldValue: any) => {
    let resetingValue: any = [];
    let initialValues = { ...this.state.initialValueField };

    const input_values = this.state.customForm?.attributes?.base_service_detail?.data.attributes.input_values
    const clientName = input_values?.data.findIndex((input: { attributes: { input_field: { name: string } } }) => input.attributes.input_field.name === 'Client Name')
    const companyName = input_values?.data.findIndex((input: { attributes: { input_field: { name: string } } }) => input.attributes.input_field.name === 'Company Name')
    const startTime = input_values?.data.findIndex((input: { attributes: { input_field: { name: string } } }) => input.attributes.input_field.name === 'Event Start Time')
    const endTime = input_values?.data.findIndex((input: { attributes: { input_field: { name: string } } }) => input.attributes.input_field.name === 'Event End Time')

    const ids = this.state.customForm?.attributes?.files?.map((file: any) => file.id)
    this.multipleAttachmentFileAPI(ids);

    for (let key in initialValues) {
      initialValues[key] = ""
      if(key.length <= 2) return
      if(key === "note") {
        setFieldValue(key, "");
      }else if(input_values?.data[clientName].attributes.id.toString() === key){
        setFieldValue(key, input_values?.data[clientName].attributes.user_input)
      }else if(input_values?.data[companyName].attributes.id.toString() === key){
        setFieldValue(key, input_values?.data[companyName].attributes.user_input)
        continue;
      }else if(input_values?.data[startTime].attributes.id.toString() === key || input_values?.data[endTime].attributes.id.toString() === key){
        resetingValue.push({ id: key, user_input: null });
        setFieldValue(key, null);
      }else{
        resetingValue.push({ id: key, user_input: null });
        setFieldValue(key, "");
      }
    }

    this.setState({
      uploadedFiles: [],
      packageSelectedItem: [],
      errorPostCode: false,
      groupByAdditionalAddon: [],
      isEventStartTime: false,
      initialValueField:resetingValue,
      conditionalDaysCoverage: "", 
      additionalServicesSubCategories:[],
      requiredInputFieldsValue: resetingValue,
      continueToReview: false,
      eventDetails: {...this.state.eventDetails, location : ""}
    }, () => {
      this.removeAddons();
    });
    this.setSumitCustomForm(resetingValue,true);
    this.setCustomFormProjectDescription("")
  }

  setSumitCustomForm = (dataVal: any,is_Reset?:boolean) => {
    let body:{ inquiry_id: any; input_values: any; is_reset?: boolean } = {
      inquiry_id: this.props.navigation.getParam("id"),
      input_values: dataVal,
    }

    if (is_Reset){
      body.is_reset = true;
    }

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveInquiryApliCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveformEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  getAVdata = () => {
    const { sub_category_name } = this.state.customForm.attributes;
    const AVPackage = this.state.packageSlectedList.find((item) => item.title === 'AV Packages');

    let subCategoryIndex = -1;
    if (sub_category_name === "Multi-Day") {
      subCategoryIndex = AVPackage.subCategories.indexOf("Multi-Day");
    } else if (sub_category_name === "AV") {
      subCategoryIndex = AVPackage.subCategories.indexOf("AV");
    }

    if (subCategoryIndex === -1) {
      subCategoryIndex = 0;
    }
  
    const selectedSubCategoryId = AVPackage.sub_category_id[subCategoryIndex];
  
    const ids = {
      service_id: AVPackage.id,
      sub_category_id: selectedSubCategoryId,
    };
    return ids;
  }

  receiveAVPacks = async () => {
    const AVPackage = this.getAVdata();

    if (!this.state.token) {
      return;
    }

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };

    const httpBody = {
      inquiry: { ...AVPackage }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createInquiryId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCustomFormEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  redirectToAVPacks = (responseJson: any, base_service_detail: any, service_id: number, id: number) => {
    const groupByCoverage = this.groupByCoverage(responseJson)
    this.setState(prevState => ({
      ...prevState,
      isLoading: true,
      enquiryDetails: {
        ...prevState.enquiryDetails,
        id: responseJson.inquiry.data.attributes.id
      },
      base_service_detail,
      groupByCoverage,
      categoryId: service_id,
      isInfoMessage: false,
      infoMessage: {
        infoText: '',
        buttonText: ''
      },
      additionalServicesSubCategories: [],
      packageSelectedItem: []
    }), () => {
      this.getInquiry(id);
      this.handleCategoriesApiResponse(this.state.categoryList);
      this.props.navigation.navigate("CustomForm", { id });
    }
    );
  }

  setCustomFormProjectDescription = (value: string) => {

    let body = {
      inquiry_id: this.state.customForm.attributes && this.state.customForm.attributes.id,
      note: value
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.saveProjectDescriptionApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateProjectDescription);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPutMethod);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify({ "Content-Type": configJSON.getUserListApiContentType, token: this.state.token }));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  isPackageDisabled = (packageName: any) => {
    const { additionalServicesSubCategories, isAVSelected, base_service_detail } = this.state;

    const services = ["Av", "Visual", "Audio"];
    const isAVAudioOrVisualPackage = services.some(service => base_service_detail?.service_name?.includes(service));
  
    const isBaseServiceAV = base_service_detail?.service_name?.includes("AV");
  
    const hasAdditionalService = (title:any) => 
      additionalServicesSubCategories?.some(item => item?.title === title);
  
    const isPackageType = (packageTitle:any, types:any) => types.includes(packageTitle);
  
    if ( isAVSelected && isPackageType(packageName.title, ["Visual Packages", "Audio Packages"])) {
      return true;
    }
  
    if (
      this.isAudioOrVisualService() &&
      isPackageType(packageName.title, ["AV Packages", "Audio Packages", "Visual Packages"])
    ) {
      return true;
    }
  
    if (
      !isAVAudioOrVisualPackage && 
      packageName.title === "AV Packages" && 
      (hasAdditionalService("Audio Packages") || hasAdditionalService("Visual Packages"))
    ) {
      return true;
    }
  
    if (
      packageName.title === "Visual Packages" && hasAdditionalService("Audio Packages") ||
      packageName.title === "Audio Packages" && hasAdditionalService("Visual Packages")
    ) {
      return true;
    }
  
    if (
      isBaseServiceAV && 
      isPackageType(packageName.title, ["Visual Packages", "Audio Packages"])
    ) {
      return true;
    }
  
    if (packageName?.subCategories?.length === 0) {
      return true;
    }
  
    const allSubCategoriesAreMultiDay = packageName?.subCategories?.every((category: any) =>
      category.includes("Multi-Day")
    );
  
    if (allSubCategoriesAreMultiDay) {
      return true;
    }
    return false;
  };

  uploadErrorHandler = (error: unknown, file: DuplicateFileType) => { 
    const duplicateFile = { name: file.name, progress: file.progress };
    const uploadedFile = this.state.uploadedFiles.filter((file) => file.name !== duplicateFile.name)
      this.setState((prevState) => ({
        uploadedFiles: uploadedFile
      }));
  };

uploadProgressFunc = (progressEvent: AxiosProgressEvent, file: {progress : number}) => {
  const percentCompleted = progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 0;
  const updateProgress = () => {
    if (file.progress < 100) {
      file.progress = percentCompleted;
      this.setState((prevState) => ({
        uploadedFiles: prevState.uploadedFiles.map((uploadedFile) =>{
         return uploadedFile === file ? file : uploadedFile
        }
        ),
      }));
    }
  };
  updateProgress();
}

setAttachmentsForm = async (file: FileType) =>{
  const formData = new FormData();
  formData.append('file', file.file, file.file.name);
  formData.append('inquiry_id', this.props.navigation.getParam("id"));
  this.setState((prevState) => ({
    uploadedFiles: prevState.uploadedFiles.map((uploadedFile) =>
      uploadedFile.name === file.name
        ? { ...uploadedFile, status: "Uploading", progress: 0 }
        : uploadedFile
    ),
    isFilesUploaded: true,
  }));
  const header = {
    token: this.state.token,
  };

  if(!navigator.onLine) {
    const error = new Error("No internet connection. Please try again when online.");
    throw error;
  }

  try {
    const response = await axios.put(
      `${baseURL}/${configJSON.uploadAttacmentEndPoint}`,
      formData,
      {
        headers: header,
        onUploadProgress: (progressEvent: AxiosProgressEvent) =>
          this.uploadProgressFunc(progressEvent, file),
      }
    );

    if (response.status === 200) {
      this.setState((prevState) => ({
        uploadedFiles: prevState.uploadedFiles.map((uploadedFile) =>
          uploadedFile.name === file.name
          ? { ...uploadedFile, status: "Completed", progress: 100 }
          : uploadedFile
        ),
        isFilesUploaded: false
        
    }));
    this.callInquiryAfterSubmission();
  }
  } catch (error) {
    console.error('Upload error:', error);
    this.retryUpload(file);
  }
};

retryUpload = (file: FileType, attempt = 1) => {
  if (attempt > 3) {
    console.error(`Upload failed after 3 attempts: ${file.name}`);
    return;
  }
  setTimeout(() => {
      this.setAttachmentsForm(file);
  }, 2000 * attempt);
};

handleDocFileChange = (e: any) => {
  const files = e.target.files;
  const allowedTypes = [
    '.pdf', '.ppt', '.pptx', '.xlsx', '.xls', 
    '.doc', '.docx', '.txt', '.jpg', '.jpeg', 
    '.png', '.gif'
  ];

  let newFiles = Array.from(files).map((file) => {
    let typedFile = file as File;
    let fileName = typedFile.name;
    const fileExt = '.' + fileName.split('.').pop()?.toLowerCase();
    if (!allowedTypes.includes(fileExt)) {
      console.warn(`File type ${fileExt} is not supported. Please upload only ${allowedTypes.join(', ')} files.`);
    }

    const uniqueFileName = this.getUniqueFileName(fileName);
    const renamedFile = new File([typedFile], uniqueFileName, {
      type: typedFile.type, 
      lastModified: typedFile.lastModified
    });
    
    return {
      name: uniqueFileName,
      progress: 0,
      status: 'Uploading',
      file: renamedFile
    };
  });

  this.copyFilesArray = [...this.copyFilesArray, ...newFiles];
  this.handleLimitedUploadFiles(newFiles);
};


  removeAttachmentsForm = (ids: number[]) => {
    if (!Array.isArray(ids) || ids.length === 0) {
      return;
  }
    const updatedFiles = this.state.customForm.attributes && this.state.customForm.attributes.files?.filter(
      (file: any) => !ids.includes(file.id)
    );
  
    this.setState((prevState) => ({
      customForm: {
        ...prevState.customForm,
        attributes: {
          ...prevState.customForm.attributes,
          files: updatedFiles
        }
      }
    }));
    this.setState((prevState) => ({
      uploadedFiles: prevState.uploadedFiles.filter(file => !ids.includes(file.file.id))
    }));

    let formData = new FormData();
    formData.append('inquiry_id', this.props.navigation.getParam("id"));
    ids?.forEach((id: any) => {
      formData.append('remove_file_id', id);
    })

    const header = { token: this.state.token, };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.uploadAttachmentsApiCallIdRemove = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.uploadAttacmentEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id,
      requestMessage);

  }

  submitForm = () => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitInquiryAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/submit_inquiry?inquiry_id=${this.state.enquiryDetails.isEnquiry ? this.state.enquiryDetails.id : this.props.navigation.getParam("id")}&new_status=pending`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  approveForm = () => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitInquiryAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/submit_inquiry?inquiry_id=${this.props.navigation.getParam("id")}&new_status=pending`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  rejectForm = () => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitInquiryAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/submit_inquiry?inquiry_id=${this.props.navigation.getParam("id")}&new_status=rejected`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  multipleAttachmentFileAPI = (idss:number[]) => {
    const updateFile = this.state.customForm.attributes && this.state.customForm.attributes.files?.filter(
      (file:any) => {
        return !idss.includes(file.id);
      }
    );
    this.setState((prevState) => ({
      customForm: {
        ...prevState.customForm,
        attributes: {
          ...prevState.customForm.attributes,
          files: updateFile
        }
      }
    }));

    this.setState((prevState) => ({
      uploadedFiles: prevState.uploadedFiles.filter(file => !idss.includes(file.file.id))
    }));

    const requestBody = {
      inquiry_id: this.props.navigation.getParam("id"),
      remove_file_ids: idss
    };
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.multipleAttachmentAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/remove_multiple_attachment`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), 
      JSON.stringify(requestBody) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    )
    runEngine.sendMessage(requestMessage.messageId, requestMessage);
  }

  savetoDraft = () => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.draftInquiryAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/submit_inquiry?inquiry_id=${this.props.navigation.getParam("id")}&new_status=draft`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  
  loqateApiLocation = (textValue:any) => {
    if(!textValue){
      this.setState({ suggestionsLocation: [], noResults: false, openModal: false })
      return;
    }
    const header = {
      "Content-Type" : configJSON.getUserListApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loqateApiCallId = requestMessage.messageId; 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_invoice/invoice/lookup?key=${getLoqateEnvironment()}&text=${textValue}&country=GBR`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    )
    runEngine.sendMessage(requestMessage.messageId, requestMessage);
  };

  calculatedAmount = () => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.calculatedAmountAPiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/calculate_cost?inquiry_id=${this.state.enquiryDetails.isEnquiry ? this.state.enquiryDetails.id : this.props.navigation.getParam("id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePageChange = (page: number) => {
    this.setState({ currentPage: page });
  };

  getcategoryList = async (params: any) => {
    const categoryValue = params !== "" ? params : "all enquiries";
    const modifiedParams = params !== "all enquiries" ? params.toLowerCase().replace("enquiries", "").trim() : params;
    this.setState({ category: categoryValue });
    this.setState({ status: params })
    let token: any = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/inquiries?status=${modifiedParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    this.setState({isLoading:true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUniqueFileName = (fileName: string) => {
    const existingFiles = this.state.uploadedFiles
      const existingFileNames = existingFiles.map(file => file.name);  
    if (!existingFileNames.includes(fileName)) {
      return fileName; 
    }  
    const nameParts = fileName.split('.');
    const baseName = nameParts.slice(0, -1).join('.');
    const extension = nameParts.length > 1 && `.${nameParts.pop()}` ;  
    let counter = 1;
    let newFileName = `${baseName}_${counter}${extension}`;
  
    while (existingFileNames.includes(newFileName)) {
      counter++;
      newFileName = `${baseName}_${counter}${extension}`;
    }  
    return newFileName;
  };

  closeLimitReachedPopup = () => {
    this.setState({ maxUploadLimitReached: false });
  }
  
  handleLimitedUploadFiles = (newFiles: { name: string, progress: number, status: string, file: File }[]) => {
    this.setState({errorMessagess: "", duplicateUploadedFiles:[]});
    const maxSizeMB = 100;const maxSizeBytes = maxSizeMB * 1024 * 1024;
    let exceededFiles: string[] = [];
    let validFiles: any[] = [];
    let duplicateFiles: any[] = [];
    newFiles.forEach(originalFile => {
        const file = { ...originalFile };
        if (file.file.size > maxSizeBytes) {
            exceededFiles.push(file.name);
            duplicateFiles.push({
                ...file,
                status: 'Canceled',
                progress: 0
            }
          );
        } else {
            validFiles.push(file);
        }
    });
    if (exceededFiles.length > 0) {
        this.setState({
            errorMessagess: `The following file(s) exceed the ${maxSizeMB} MB limit: ${exceededFiles.join(', ')}`,
             duplicateUploadedFiles: [...this.state.duplicateUploadedFiles, ...duplicateFiles]
        });
    }
    if (this.state.uploadedFiles.length + validFiles.length > 10) {
        validFiles = validFiles.slice(0, 10 - this.state.uploadedFiles.length);
        this.setState({ maxUploadLimitReached: true });
    }
    if (validFiles.length > 0) {
        this.setState((prevState) => ({
            uploadedFiles: [...prevState.uploadedFiles, ...validFiles],
            customForm: {
                ...prevState.customForm,
                attributes: {
                    ...prevState.customForm.attributes,
                    files: [...prevState.uploadedFiles, ...validFiles]  
                }
            }
        }));
        validFiles.forEach((validFile, index) => {
            setTimeout(() => {
                this.simulateFileUpload(validFile);
            }, 200 * index);
        });
    }
}
  handleSortClick = () => {
    const { sortDirection, categoryList } = this.state;
    const sortedData = [...categoryList.inquiries.data].sort((a: any, b: any) => {
      if(typeof a.attributes.event_date !== 'string' || typeof b.attributes.event_date !== 'string' ){
        return 0
      }
      const [dayA, monthA, yearA] = a.attributes.event_date && a.attributes.event_date.split('/').map(Number)
      const [dayB, monthB, yearB] = b.attributes.event_date && b.attributes.event_date.split('/').map(Number)
      const dateA = new Date(yearA, monthA - 1, dayA).getTime();
      const dateB = new Date(yearB, monthB - 1, dayB).getTime();
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    this.setState({ sortDirection: newSortDirection, categoryList: { inquiries : {data: sortedData}  } });
  };

  getTimeString = (value: any) => {
    if (!value) return '';
    const ukAndGreatBritainTimeZones = ['Europe/London', 'Europe/Guernsey', 'Europe/Isle_of_Man', 'Europe/Jersey'];
    const userTimeZone = momentTimezone.tz.guess();
    const isUKOrGreatBritain = ukAndGreatBritainTimeZones.includes(userTimeZone);
    const isDaylightSavingTime = momentTimezone().tz(userTimeZone).isDST();
    if (moment.isMoment(value)) {
      let format = isDaylightSavingTime ? "HH:mm [BST]" : "HH:mm [GMT]";
      if (!isUKOrGreatBritain) {
        format = "HH:mm [GMT]"
      }
      return `${value.format(format)}`;
    } else if (typeof value === 'string') {
      let format = `${value} ${isDaylightSavingTime ? 'BST' : 'GMT'}`;
      if (!isUKOrGreatBritain) {
        format = `${value} GMT`;
      }
      return format;
    } else if (value instanceof Date) {
      const hours = value.getHours().toString().padStart(2, '0');
      const minutes = value.getMinutes().toString().padStart(2, '0');
      let format = `${hours}:${minutes} ${isDaylightSavingTime ? 'BST' : 'GMT'}`
      if (!isUKOrGreatBritain) {
        format = `${hours}:${minutes} GMT`;
      }
      return format;
    } else {
      return '';
    }
  };

  simulateFileUpload = async (file: any) => {  
    const newFile = new File([file.file], file.name, {
      type: file.file.type, 
      lastModified: file.file.lastModified,
    });  
    const updatedFileObject = {
      ...file,
      file: newFile,
    };    
    await this.setAttachmentsForm(updatedFileObject);
  };

  handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation()
  }

  handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;
    let newFiles = Array.from(files).map((file) => {
      const typedFile = file as File;
      return {
        name: typedFile.name,
        progress: 0,
        status: 'Uploading',
        file: typedFile
      }
    });

    this.handleLimitedUploadFiles(newFiles)
  };

  setInfoPaperStyle = () => ({
    minWidth: this.state.infoMessage.buttonText ? "463px" : "342px",
  });

  setButtonStyle = () => {
    return {
      display: this.state.infoMessage.buttonText ? 'block' : 'none', cursor: 'pointer'
    }
  };

  setBackgroundColor = (selectedSubCat: string, subCategory: string) => {

    return selectedSubCat === subCategory ? "#BDB9FB" : "#fff";
  };

  openDocFileInput = () => {
    if (this.fileInput.current) {
      this.fileInput.current.click();
    }
  };
  setCategoryTxt = (text: string) => {
    this.setState({ category: text }, () => this.getcategoryList(this.state.category));
  };

  setFilterTxt = (textLabel: string) => {
    this.setState({ filterEnquiries: textLabel })
    this.getInquiryFilter(textLabel)
  }
  goToHelpCenterCustom() {
    this.props.navigation.navigate("HelpCenter");
  }

  goToService = () => {
    this.props.navigation.navigate("Categoriessubcategories");
  }

  openRBSPopup = async() => {
    const popupName = await getStorageData("role")
    this.setState({ openDialogName: popupName || "" });
  }

  goToDraftEnquire = async () => {

    const durationErrorMessage = this.handleEventDurationValidation();
    if (durationErrorMessage) {
      this.setState({ eventDetails: { ...this.state.eventDetails, durationError: durationErrorMessage } })
      this.setState({ openDialogName: "ErrorForPackages", errorMessage:durationErrorMessage})
    } else {
      this.savetoDraft(); 
    }
  };

  goToProfileCustom() {
    this.props.navigation.navigate("CustomisableuserProfiles");
  }
  goToiCalendar = () => {
    this.getInquiry()
    this.calculatedAmount()
    this.setState({ openDialogName: "OpenICalendar" });

  }
  goToSettingsCustom() {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToQuoteCustom = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleOpenDialog = () => {
    this.setState({ isDialogOpen: true})
  }

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false})
  }

  getCompanyLocations = async () => {
    const { company_id } = this.state;

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLocationAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/company_locations/${company_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    this.getLocationAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  locationFn = (event: any) => {
    const inputValue = event.target.value.replace(/\s/g, '');
    if (event.target.value.length >= 1 && event.target.value.length < 9) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.postalCodeKey = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `https://api.postcodes.io/postcodes?q=${inputValue}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({ openModal: true, errorPostCode: false, locationCheck: true });
    }
    else if (event.target.value.length == 0) {
      this.setState({ openModal: false, locationCheck: true });
    }
  }

  postalCodeOptions = (response: { result: [] }) => {
    let postalData: string[] = [];
    if (response.result) {
      response.result.map((code: { postcode: string }) => {
        postalData.push(code.postcode)
      });
      this.setState({ address: postalData });
    }
  };


   toggleDropdown = () => {
    this.setState((prevState) => ({
      openModal: prevState.suggestionsLocation.length > 0 ? !prevState.openModal : false, 
    }));
  };
  handleCalendarTypeChange = (event: any) => {
    if (event.target.value === "download" || event.target.value === "apple") {
      this.setState({ calendarType: event.target.value }, () => this.handleCalendarChange());
    }
    else {
      this.setState({ calendarType: event.target.value });
    }
  };

  generateUID() {
    let buffer = new Uint32Array(2);
    crypto.getRandomValues(buffer);
    let n = (buffer[0] * 0x100000000 + buffer[1]).toString();
    return new Date().getTime().toString() + "_" + n;
  }

  handleCalendarChange = () => {
    const { calendarType, base_service_detail } = this.state;

    const inputValues = base_service_detail.input_values.data;

    const eventNameInput = inputValues.find(
      (input: any) => input.attributes.input_field.name === "Event Name"
    );
    const eventName = eventNameInput ? eventNameInput.attributes.user_input : '';

    const eventStartTimeInput = inputValues.find(
      (input: any) => input.attributes.input_field.name === "Event Start Time"
    );
    const eventStart = eventStartTimeInput ? eventStartTimeInput.attributes.user_input : "";

    const eventEndInput = inputValues.find(
      (input: any) => input.attributes.input_field.name === "Event End Time"
    );
    const eventEnd = eventEndInput ? eventEndInput.attributes.user_input : "";

    const eventDateInput = inputValues.find(
      (input: any) => input.attributes.input_field.name === "Event Date"
    );
    const eventDate = eventDateInput ? eventDateInput.attributes.user_input : "";

    const eventLocationInput = inputValues.find(
      (input: any) => input.attributes.input_field.name === "Location / Venue"
    );
    const eventLocation = eventLocationInput ? eventLocationInput.attributes.user_input : "";
    try {
      const formattedDate = eventDate.split('T')[0].replace(/-/g, '');
      const formattedStartTime = eventStart.replace(':', '') + '00';
      const formattedEndTime = eventEnd.replace(':', '') + '00';
      const formattedStartDate = `${formattedDate}T${formattedStartTime}Z`;
      const formattedEndDate = `${formattedDate}T${formattedEndTime}Z`;
      const formattedDateYa = eventDate.split('T')[0];
      const formattedStartTimeYa = eventStart.padStart(5, '0') + ':00';
      const formattedEndTimeYa = eventEnd.padStart(5, '0') + ':00';
      const formattedStartDateYa = `${formattedDateYa}T${formattedStartTimeYa}Z`;
      const formattedEndDateYa = `${formattedDateYa}T${formattedEndTimeYa}Z`;
      const startDateObj = new Date(formattedStartDateYa);
      const endDateObj = new Date(formattedEndDateYa);
      const durationMinutes = (endDateObj.getTime() - startDateObj.getTime()) / 60000;
      const hours = Math.floor(durationMinutes / 60);
      const minutes = Math.round(durationMinutes % 60);
      const formattedDuration = `${hours.toString().padStart(2, '0')}${minutes.toString().padStart(2, '0')}`;
      const outlookStartDate = eventDate.split('T')[0]
      const [shours, sminutes] = eventStart.split(':');
      const [ehours, eminutes] = eventEnd.split(':');
      const outformattedStartTime = `${shours.padStart(2, '0')}:${sminutes.padStart(2, '0')}:00`;
      const outformattedEndTime = `${ehours.padStart(2, '0')}:${eminutes.padStart(2, '0')}:00`;
      const outformattedStartDate = `${outlookStartDate}T${outformattedStartTime}`;
      const outformattedEndDate = `${outlookStartDate}T${outformattedEndTime}`;
      const outlookSubject = encodeURIComponent(eventName);
      const outlookLocation = encodeURIComponent(eventLocation);
      const timezone = "GMT";
      const uid = this.generateUID()

      switch (calendarType) {
        case "google":
          window.open(
            `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(
              eventName
            )}&dates=${encodeURIComponent(formattedStartDate)}%2F${encodeURIComponent(
              formattedEndDate
            )}&location=${encodeURIComponent(
              eventLocation
            )}&ctz=${encodeURIComponent(timezone)}`,
            "_blank"
          );
          break;
        case "outlook":
          window.open(
            `https://outlook.live.com/owa/?rru=addevent&&subject=${outlookSubject}&startdt=${outformattedStartDate}&enddt=${outformattedEndDate}&location=${outlookLocation}&allday=false&uid=${uid}&path=/calendar/view/Month`,
            "_blank"
          );
          break;
        case "yahoo":
          window.open(
            `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${encodeURIComponent(eventName)}&st=${encodeURIComponent(formattedStartDate)}&dur=${formattedDuration}&desc=&in_loc=${encodeURIComponent(eventLocation)}&tz=GMT`,
            "_blank"
          );
          break;
        case "download":
        case "apple":
          this.setState({
            eventDetails: {
              title: eventName,
              location: eventLocation.replaceAll(',', ''),
              startTime: moment(outformattedStartDate).local().format("YYYY-MM-DD HH:mm:ss"),
              endTime: moment(outformattedEndDate).local().format("YYYY-MM-DD HH:mm:ss"),
              durationError: '',
            }
          });
          break;
        default:
      }
    } catch (error) {

    }
  };



  formatDate = () => {
    const currentDate = new Date();
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    return currentDate.toLocaleDateString('en-US', options);
  }

  postCodeApi = async (addressCode: any) => {
    try {
      const response = await axios.get(`https://api.postcodes.io/postcodes/${addressCode}/nearest`);
      const { data } = response;
      if (data && data.result) {
        this.setState({ address: data.result, errorPostCode: false });
      } else {
        this.setState({ address: 'Address not found' });
      }
    } catch (error) {
      this.setState({ errorPostCode: true, address: [] });
    }
  }

  cancelSpeakTOExpert = () => {
    this.setState({ openToExpertDialog: false })
  }

  cancelMeetWithManager = () => {
    let errorOccuredInputId = this.state.warningMessageBody.input_value_id
    let nameOfTheInput = this.state.warningMessageBody.name
    let data = { id: errorOccuredInputId, user_input: "" }
    if (nameOfTheInput === "How Many Days Coverage?") {
      const { sub_category_name } = this.state.customForm.attributes;
      const isFullDayOrMedia = ['Full-Day', 'Visual', 'AV', 'Audio'].some(type => sub_category_name?.includes(type));
      if (sub_category_name?.includes("Half-Day")) data.user_input = "0.5";
      if (isFullDayOrMedia) data.user_input = "1";
    }

    this.saveData(data)
   
    const copyInitialInputFieldValues = { ...this.state.initialValueField }
    copyInitialInputFieldValues[errorOccuredInputId] = data.user_input

    this.setState({
      warningMessage: "",
      meetWithExpertDialog: false,
      warningMessageBody: {
        input_value_id: 0,
        error: "",
        name: ""
      },
      initialValueField: copyInitialInputFieldValues,
    })
  }

  meetLink = () => {
    const meeting_link = this.state.customForm.attributes.meeting_link
    window.open(meeting_link, '_blank')
  }

  isValidMeetLink = () => {
    const meeting_link = this.state.customForm && this.state.customForm.attributes && this.state.customForm.attributes.meeting_link
    const regExp = new RegExp(/^(https?:\/\/)[^\s]+$/i)
    return regExp.test(meeting_link)
  }

  approveApiFN = () => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.approveKey = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/approve_inquiry?inquiry_id=${this.state.enquiryDetails.isEnquiry ? this.state.enquiryDetails.id : this.props.navigation.getParam("id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  rejectApiFN = () => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rejectKey = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/reject_inquiry?inquiry_id=${this.state.enquiryDetails.isEnquiry ? this.state.enquiryDetails.id : this.props.navigation.getParam("id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  rejectCancel = () => {
    this.setState({ rejectState: false });
  }

  checkRequiredFields() {
    return !Object.values(this.state.requiredInputFieldsValue).every(field => field !== "" && field !== null && field !== '00:00');
  }

  getValidationError() {
    let message = '';
    if (this.checkRequiredFields()) {
      message = 'Ensure all required information is provided.';
    } else if (this.state.locationCheck) {
      message = 'Location is mandatory.';
    } else if (this.state.previousEventDateLessThenCurrentDate) {
      message = "Event Date can't be past date. Please select future date."
    }
    else if (this.state.previousEventDateEqualCurrentDate) {
      message = "Event date can't be the same date. Please select another future date."
    } else if (this.state.eventDetails.durationError === "more than 9" || this.state.errorMessage === "Event duration can't be more than 9 hours") {
      message = "Event duration can't be more than 9 hours";
    } else if (this.state.eventDetails.durationError === "more than 5") {
      message = "Event duration can't be more than 5 hours"
    }
    return message;
  }

  requiredInputValidation(inputId: any) {
    const value = this.state?.requiredInputFieldsValue[inputId] || "";
    return (this.state.continueToReview && (
      value === null
      || value === ""
      || value === "00:00"
      || value.length === 0)
  );
}

  editEnquiry = () => {
    this.props.navigation.navigate("CustomForm", { id: this.state.enquiryDetails.id });
  }

  getTimezone = () => {
    const timeZone = momentTimezone.tz.guess();
    const isDaylightSavingTime = momentTimezone().tz(timeZone).isDST();
    const uk_GreatBritainTimeZones = ['Europe/London', 'Europe/Guernsey', 'Europe/Isle_of_Man', 'Europe/Jersey'];
    const isUKorGreatBritain = uk_GreatBritainTimeZones.includes(timeZone);
    let format = isDaylightSavingTime ? "BST" : "GMT"
    if (!isUKorGreatBritain) {
      format = "GMT";
    }
    return format
  }

  dupModalClose = () => {
    this.setState({ dupModalOpen: false })
  }

  handleInputChange = (id: string, value: string) => {
    this.setState({
      noResults: false, 
      hasSelected: false,
  });
  
    const initialValues = { ...this.state.initialValueField }
    initialValues[id] = value
    this.setState({ initialValueField: initialValues }
  )
  }
  handleBlur = () => {
    if (!this.state.hasSelected) {
        this.setState({ noResults: true }); 
    }
};

  handleDownload = (enqID: string | number) => {
    if (enqID) {
      this.setState({ errorCell: true });
    } else {
      this.setState({ errorCell: false });
    }
  }

  handleURL = (urlLink: string) => {
    if (urlLink.includes("request-quote")) {
      const pathSegments = urlLink.split('/');
      const reqId = pathSegments[pathSegments.length - 1];
      this.viewReq(reqId)
    }
  }

  handleTimeValidatn = (value: string) => {
    value = value.replace(/\D/g, '');
    if (value.length > 4) {
      value = value.slice(0, 4);
    }

    if (value.length >= 2) {
      value = value.slice(0, 2) + ':' + value.slice(2);
    }
    return value;
  };

  renderProvCost = (num1: string, num2: string,) => {
    if (num1 && num2) {
      let provCost = Number(num1.replace(",", "")) + Number(num2.replace(",", ""))
      return provCost.toLocaleString()
    }
  }

  handleFormatCorrectDate = (enteredDate: string | null | Date) => {
    if (typeof enteredDate == "string") {
      const [days, month, year] = enteredDate.split("/");
      if (days && month && year) {
        return new Date(Number(year), Number(month) - 1, Number(days));
      }
      return null;
    } else {
      return enteredDate
    }
  }
  
  renderSelectedMenu = (data : MenuData) => {
      let menuData = data.attributes.input_field.options && data.attributes.input_field.options.split(',').map(function (item: string) {
         return item.trim();
       })
       return menuData
  }
  handleSaveInquiry() {
    this.handleUpdateAdditionalServiceCost()
    this.handleCalculateCostApiCall();
  }

  handleDropdownToggle = (isOpen: boolean) => {
    this.setState({ isDropdownOpen: isOpen });
  };
  redirectToFullPackage = (serviceType: string) => {
    let matchingServiceName :string = "";
    if(serviceType.includes("Half-Day")){
      matchingServiceName = "Full-Day"
    }else{
      matchingServiceName = "Half-Day"
    }
    const { service_name, related_sub_category} = this.state.customForm.attributes;
    if(related_sub_category === null){
      return;
    }
   const filteredArray = this.state.categoryList.data?.filter((item:any) => item.attributes.name === service_name)
   const allSubCategories = filteredArray[0].attributes.sub_categories
    const subcategory = allSubCategories.data.find((item:any) =>
      item.attributes.name.includes(matchingServiceName)
    );
    this.navigateToFullPackage(related_sub_category?.id)


  }
  navigateToFullPackage = async (subCategoryId:any) => {
    const { service_id} = this.state.customForm.attributes;
    if (!this.state.token) {
      return;
    }

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };

    const httpBody = {
      inquiry: { service_id: service_id, sub_category_id: subCategoryId }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createInquiryCoverageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCustomFormEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  callInquiryAfterSubmission = async () => {
      const url = window.location.pathname
      this.handleURL(url)
      const pathSegments = url.split('/');
      const reqId = pathSegments[pathSegments.length - 1];
      if (reqId) {
      await this.getInquiry(reqId);
      }
  }

  handleSelectWidth = () => {
    if (this.selectRef.current) {
      this.setState({ menuWidth: this.selectRef.current.node.clientWidth });
    }
  };
  
  // Customizable Area End
}