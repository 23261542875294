import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Typography,
  IconButton,
  Checkbox,
  Grid,
  Button,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { styled } from "@material-ui/styles";
import { View, StyleSheet, Text, Image} from "react-native";
import {
  imgBanner,
  imgLogo,
  imgClose
} from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";

const CustomFormWrapper = styled("div")({
  position: "relative",
  height: 46,
  display: "block"
});


const SubmitButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#000",
  border: "solid 3px #000",
  borderRadius: 0,
  textTransform: "none",
  fontSize: "14px",
  width: "100%",
  padding: "4px",
  fontFamily:'CircularStdBold', 
  "&:hover": {
    backgroundColor: "#BDB9FB",
  },
  "&:focus": {
    backgroundColor: "#DEDCFD",
  }
});

const CustomInputStyle = styled(Input)({
  "& .MuiInputBase-fullWidth": {
    margin: 0
  },
  "& .MuiInputBase-input": {
    backgroundColor: "#fff",
    height: "38px",
    boxSizing: "border-box",
    paddingBottom: "9px",
    paddingTop:"9px",
    position: "relative",
    zIndex: 1,
    borderColor: "#000",
    width: "100%",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderStyle: "solid",
    borderWidth: 3,
    fontFamily:'CircularStd', 
    fontSize:14,
    borderRadius: 0
  },
  "& inpur:before": {
    content: "none"
  },
  "& .MuiInputAdornment-positionEnd": {
    position:"absolute",
    zIndex:1,
    right:0,
  }

});
const CustomButtonOutline = styled("div")({
  border: "solid 3px #000",
  height: 33,
  position: "absolute",
  right: "-5px",
  bottom: '2px',
  width: "98%",
  backgroundColor: "#594FF5",
  zIndex: 0,
});
const AlertBox = styled("div")({
  padding:20,
  backgroundColor: "#FEE2E2",
  borderLeftColor:"#FF5B74",
  borderStyle:"solid",
  marginBottom:30,
  color:"#FF5B74",
  width:'100%',
  border:0,
  borderLeft:4,
  boxSizing:'border-box',
  borderTopLeftRadius:5,
  borderBottomLeftRadius:5,
  fontFamily: 'CircularStd',
});

const styles = StyleSheet.create({
  customLabel: {
    fontSize: 16,
    color: "#000",
    marginBottom: 5,
    display: "flex",
     fontFamily: 'CircularStdBold',
  },
  staticBottomContent: {
    maxWidth:'596px',
    alignItems: "center",
    padding: "0 20px",
    marginLeft: "auto",
    marginRight:"auto",
  }
});

const LoginContainerGrid = styled(Grid)({
  '& .inputPassword':{
      paddingTop: "11px"
  },
  '@media screen and (max-width:640px)':{
    '& .leftContainer':{
      display: "none !important"
    },
    '& .rightContainer':{
      maxWidth: "100% !important",
      flexBasis: "100% !important"
    }
  }
})

const ResponsiveDialogContent = styled(DialogContent)({
  '&.pt-80':{
    paddingTop:'80px'
  },
  '&.pb-40':{
    paddingBottom:'40px'
  },
  '& .content':{
    maxHeight:'365px',
    overflow:"auto",
    paddingLeft:'36px', 
    paddingRight:"36px",
    fontFamily: 'CircularStd',
    fontSize: '14px',
    lineHeight: '18px'
  },
  '& .title':{
    paddingTop:'30px',
    paddingBottom:'30px',
    paddingLeft:'36px', 
    paddingRight:"36px",
    fontFamily: 'CircularStd',
    fontSize: '30px',
    fontWeight: '700',
    lineHeight: '44px',
    textAlign: 'center'
  }
});


const configJSON = require("./config");
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";
import { loadingImg } from "../../customform/src/assets";

const visibilites = new Map([[true, <VisibilityOff />], [false, <Visibility />]]);
const passwordType = new Map([[true, "password"], [false, "text"]]);
// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 termAndPrivacyBox=()=>{
  return <>{
            this.state.popupContentType === "termService" ? 
            <>
              <div className="title" dangerouslySetInnerHTML={{ __html: this.state.termOfService.title }}></div>
              <div className="content" dangerouslySetInnerHTML={{ __html: this.state.termOfService.content }}></div>
            </> :
            <>
            <div className="title" dangerouslySetInnerHTML={{ __html: this.state.privacyStatement.title }}></div>
            <div className="content" dangerouslySetInnerHTML={{ __html: this.state.privacyStatement.content }}></div>
          </>
          }
        </>
 }
 // Customizable Area End
  render() {
    // Customizable Area Start
    const { isLoading } = this.state;

    if (isLoading) {
      return   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',width:"100%", backgroundColor: 'transparent' }}>
       <img src={loadingImg}  style={{height:"50%",width:"25%"}}/>
  </div>
              }
    // Customizable Area End
    return (
       // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
      <Container
        disableGutters
        maxWidth={false}
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <View style={{backgroundColor:'#594FF5',height:76,borderBottomColor:'#000',borderBottomWidth:3,borderStyle:"solid"}}></View>
      <LoginContainerGrid
        container
        style={{
          flexGrow: 1
        }}
      >
        <Grid
          item
          sm={6}
          className="leftContainer"
          style={{
            position: "relative",
            backgroundColor: "#F7F6FF",
            overflow: "hidden",
            borderRightColor:'#000',borderRightWidth:3,borderRightStyle:"solid",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Box style={{height:"fit-content", gap:"60px", display:"flex", flexDirection:"column", padding:"0 20px"}}>
            <View style={{alignItems:'center',justifyContent:'center'}}>
                <img
                  src={imgBanner}
                  alt="carousel-right-tumbnail"
                  style={{width:'100%', maxWidth:'618px'}}
                />
            </View>
          
            <View style={styles.staticBottomContent}>
              <img
                src={imgLogo}
                alt="mainImg"
                style={{ width:"80%",maxWidth:"424px", marginBottom: "30px" }}
              />
              <Typography
                style={{
                  color: "#000",
                  fontSize: "16px",
                  textAlign: "center",
                  lineHeight: "26px",
                  fontFamily: 'CircularStd',
                }}
              >
                {configJSON.supportText_1}
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: 'CircularStd',
                  lineHeight: "26px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  textAlign: "center",
                  color: "#000",
                }}
              >
                {configJSON.supportText_2}
              </Typography>
            </View>
          </Box>
         
        </Grid>
        <Grid
          sm={6}
          item
          container
          alignItems="center"
          className="rightContainer"
          justifyContent="center"
          style={{
            height: "100%",
            backgroundColor: "#FFF"
          }}
        >
          <Box sx={singInContainerStyle}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "40px",
                width: "100%",
                boxSizing: "border-box"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
               
               <Typography
                  variant="h4"
                  component="h2"
                  style={{
                    fontSize: 30,
                    color: "#000000ff",
                    backgroundColor: "#fff",
                    justifyContent: "center",
                    marginBottom: "30px",
                    display: "flex",
                    fontFamily: 'CircularStdBold !important',
                    fontWeight:600
                  }}
                >
                  {configJSON.lableHeaderLogin}
                </Typography>


                <Formik
                  initialValues={{ email: this.state.email, password: this.state.password, checkedRememberMe: this.state.checkedRememberMe}}
                  validationSchema={Yup.object().shape(
                    this.state.emailPasswordSchema
                  )}
                  validateOnMount={true}
                  validateOnChange={true}
                  enableReinitialize
                  onSubmit={(values, actions) => {
                    this.doUserLogIn(values)
                    actions.setSubmitting(false);
                  }}
                  data-testid="userLoginForm"
                >
                  {({ setFieldValue, values, handleSubmit, touched, errors }) => (
                    <form onSubmit={handleSubmit} className="mt-5 mb-2" style={{width:'100%'}}>
                      <View style={{ width: "100%" }}>

                        
                        {this.state.errorFailureMessage.length > 0 && (
                          <AlertBox data-testid="alerMessage">
                            {this.state.errorFailureMessage}
                          </AlertBox>
                        )}

                        {((touched.email && errors.email) || (touched.password && errors.password)) && (
                          <AlertBox data-testid="alerMessage">
                             <>{errors.email} {errors.password}</>
                          </AlertBox>
                        )}

                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <Text style={styles.customLabel}>{configJSON.emailLabel}</Text>
                            <CustomFormWrapper>
                              <CustomInputStyle
                                data-test-id="txtInputEmail"
                                placeholder={"Your email"}
                                fullWidth={true}
                                name="email"
                                value={values.email}
                                style={{borderRadius:0}}
                                onChange={(event) => {
                                  const trimmedValue = event.target.value.trim();
                                  setFieldValue('email', trimmedValue);
                                  this.resetErrorMessage();
                                }}
                                
                              />
                            </CustomFormWrapper>
                            
                          </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: 20 }}>
                          <Text style={styles.customLabel}>Password</Text>
                          <CustomFormWrapper>
                            <TextField
                              data-test-id="txtInputPassword"
                              type={passwordType.get(this.state.enablePasswordField)}
                              placeholder={"*********"}
                              fullWidth={true}
                              name="password"
                              value={values.password}
                              style={{borderRadius:0,border:"3px solid black", fontSize:14,fontFamily:"CircularStd", paddingRight:5,paddingLeft:5,boxSizing:"border-box", backgroundColor: "#fff"}}
                              onChange={(event) => {setFieldValue('password',event.target.value);this.resetErrorMessage();}}
                              InputProps={{
                                endAdornment:(
                                  <InputAdornment position="end">
                                  <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  data-test-id="btnPasswordShowHide"
                                  edge="end"
                                >
                                 {visibilites.get(this.state.enablePasswordField)}
                                </IconButton>
                                </InputAdornment>
                                ), disableUnderline:true
                              }}
                            />
                          </CustomFormWrapper>
                          
                        </Grid>

                        <Grid item xs={12}>
                        <Box
                          display="flex"
                          style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 20,
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: "14px",
                              color: "#64748B",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                alignItems: "center",
                                
                              }}
                            >
                              <Checkbox
                                style={{ paddingLeft: 0 }}
                                data-test-id={"btnRememberMe"}
                                onClick={() => {
                                  setFieldValue('checkedRememberMe', !values.checkedRememberMe);
                                }}
                                checked={values.checkedRememberMe}
                              />
                              <Text
                                style={{ color: "#5A5A5A", fontSize: 14,  fontFamily:'CircularStd !important', }}
                                testID={"btnRememberMe"} //Merge Engine::From BDS
                                {...this.btnRememberMeProps} //Merge Engine::From BDS - {...this.testIDProps}
                              >
                                {configJSON.labelRememberMe}
                              </Text>
                            </View>
                          </Box>
                          <Text
                            testID={"btnForgotPassword"}
                            style={{
                              fontSize: 14,
                              color: "#5A5A5A",
                              fontFamily:'CircularStd !important', 
                            }}
                            onPress={() => {
                              this.goToForgotPassword();
                            }}
                          >
                            {configJSON.forgotPassword}
                          </Text>
                        </Box>
                      </Grid>
                        <Grid item xs={12}>
                          <CustomFormWrapper>
                            <CustomButtonOutline />
                            <SubmitButton
                              data-testid={"btnEmailLogIn"}
                              type="submit"
                            >
                              {configJSON.lableHeaderLogin}
                            </SubmitButton>
                          </CustomFormWrapper>
                        </Grid>

                      </View>
                    </form>
                  )}
                </Formik>
                <Box style={{ display: "inline", fontSize: "16px", marginTop: 10 }}>
                  <Typography style={{ display: "inline", cursor: "pointer", fontFamily:'CircularStd !important',  }}>
                    By signing in, I agree to&nbsp;
                  </Typography>
                  <Box
                    style={{ display: "inline", color: "#594FF5", fontFamily:'CircularStd !important', fontWeight:600,  cursor: "pointer", }}
                    data-testid={"btnLegalTermsAndCondition"}
                    onClick={this.openPrivacyStatement}
                  >
                    {configJSON.privacyText}
                  </Box>
                  <Typography style={{ display: "inline", fontFamily:'CircularStd !important',fontWeight:500  }}> and </Typography>
                  <Typography
                    data-testid={"btnLegalPrivacyPolicy"}
                    style={{
                      display: "inline",
                      color: "#594FF5",
                      cursor: "pointer",
                      fontWeight:600,
                      fontFamily:'CircularStd !important', 
                    }}
                    onClick={this.openTermOfService}
                  >
                    {configJSON.termsService}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </LoginContainerGrid>
      <Dialog id="model_add" 
            maxWidth="md"
              open={this.state.openDialogName === "termOfService"} onClose={ this.onCancel } 
              style={{border:'3px solid black'}} scroll="body">
              <ResponsiveDialogContent className="pt-40" style={{ 
                maxWidth:'100%', width:'500px', flexDirection: 'column',paddingBottom:'55px',
                display: 'flex', alignItems:'center', border:'3px solid black', boxSizing:'border-box'}}> 
                <div className="closeWrapper" data-testid="hideModal" onClick={this.onCancel} style={{
                  top:'20px', right:'20px', 
                  cursor:"pointer", position:"absolute",
                  }}>
                  <Image source={ imgClose} resizeMode="cover" style={{ 
                    height:'20px', 
                    width:'20px',
                    }}/>
                </div>
                <Box className="contentContainer" style={{
                   flexDirection: 'column', justifyContent: 'center', 
                  alignItems: 'center', display: 'flex' }}>
                    {this.termAndPrivacyBox()}
                </Box>
              </ResponsiveDialogContent>
            </Dialog>
      </Container>
    </ThemeProvider>
       // Customizable Area End
    );
  }
}
// Customizable Area Start
const singInContainerStyle = { 
  maxWidth: "460px", 
  width:"100%",
}
// Customizable Area End