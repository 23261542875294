// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import _ from "lodash";

import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
interface TeamMembers {
  "type": string,
  "id": string,
  "attributes": {
    "name": string,
    "image": string
    "email": string | null,
    "id": number,
    "activated": boolean
  }
}
export interface ResData {
  data: [
    {
      id: string,
      type: string,
      attributes: {
        id: number,
        name: string,
        email: string,
        image: string
      }
    },
  ]
}

export interface TeamMember {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    email: string,
    image: string
  }
}

interface PhoneNumberResponse {
  message: string;
  full_phone_number: string;
  errors?: string;
}


export const configJSON = require("./config");
import { emailRegex } from "../../../components/src/EmailRegex";
export interface Props {
  navigation: any;
  id: string;
}

interface S {
  txtSavedValue: string;
  txtInputValue: string;
  enableField: boolean;
  Teams: Array<TeamMembers>
  createData: [],
  isLoading: boolean;
  allTeamMembers: Array<TeamMember>;
  allTeamMembersdup: Array<TeamMember>;
  isAddTeamMember: string;
  editableMemberId: string | null;
  searchValue: string;
  companyName:string;
  companyAccount:string;
  unsubscribedUserModal: boolean;
  token: string | null;
  showDeleteModal: boolean
  createTeamMemberModal: boolean;
  showVerificationModal: boolean;
  openModel:string;
  warningMessage:string;
  showUpdateModal: boolean;
  showUpdateSuccessMsg: boolean,
  formDataValue: {
    firstName: string,
    lastName: string,
    company: string,
    emailId: string,
    full_phone_number: any,
    jobTitle: string,
    userType: string,
    companyType: string,
    country_code: number
  },
  countryCodeSelected: string
  currentPage: number,
  itemsPerPage: number,
  currentTeams: Array<TeamMembers>
  userId: string,
  totalPage: number,
  currentItem: any,
  clientUserId:number,
  phoneNumber: string,
  prefix:string,
  successModal : boolean,
  isEmailError : boolean,
  isErrorFields : boolean,
  isPhoneError :boolean,
  phoneErrorMessageFromBE:string,
  validationErrors: {
    formError: boolean,
    emailError: boolean,
    phoneError: boolean,
  },
  can_create_ac_teamMembers : boolean,
  searchMember: string;
  isSuspended : boolean;
  suspendedMessage : string;
  suspendedTitle:string;
}

interface SS {
  id: any;
}

export default class TeamBuilderListController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTeamMemberListCallId: string = '';
  addMembersApiCallId: string = "";
  popUpconfrimembersApiCallId: string ="";
  phoneNumberValidateApiCallId: string ="";
  updateMembersApiCallId:string = '';
  getProfileAPICallId:string='';
  deleteTeamMemberDataCallId: string = '';
  verificationEmailApiCallId: string = ''
  getSearchApiCallId: string = '';
  userSuspendedApiCallId : string  =  "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.state = {
      txtSavedValue: "A",
      phoneErrorMessageFromBE:"",
      txtInputValue: "",
      enableField: false,
      Teams: [],
      createData: [],
      isLoading: true,
      allTeamMembers: [],
      allTeamMembersdup: [],
      searchValue: "",
      isAddTeamMember: "none",
      editableMemberId: null,
      unsubscribedUserModal: false,
      token: "",
      companyName:"",
      companyAccount:"",
      showDeleteModal: false,
      createTeamMemberModal: false,
      showVerificationModal: false,
      openModel:"",
      warningMessage:"",
      showUpdateModal:false,
      showUpdateSuccessMsg:false,
      formDataValue: {
        firstName: "",
        lastName: "",
        company: '',
        emailId: "",
        full_phone_number: "",
        companyType: '',
        jobTitle: "",
        userType: "",
        country_code: 0
      },
      countryCodeSelected: "",
      currentPage: 1,
      itemsPerPage: 4,
      currentTeams:[],
      totalPage:1,
      userId: "",
     
      currentItem: [],
      clientUserId: 0,
      phoneNumber: "",
      prefix: "",
      successModal : false,
      isEmailError: false,
      isErrorFields : false,
      isPhoneError : false,
      validationErrors: {
        formError: false,
        emailError: false,
        phoneError: false,
      },
      can_create_ac_teamMembers:false,
      searchMember: "",
      isSuspended : false,
      suspendedMessage : "" ,
      suspendedTitle:"",
    };

    this.handleSearch = _.debounce(this.handleSearch.bind(this), 500);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleChange = (event:any) => {
    const { name, value } = event.target;

    if(name == 'emailId'){
      this.setState((prevState) => ({
        formDataValue: {
          ...prevState.formDataValue,
          [name]: value,
        },
        isEmailError: !this.validateEmail(value),
      }));
    }

    this.setState((prevState) => ({
      formDataValue: {
        ...prevState.formDataValue,
        [name]: value,
      }
    }));
  };
  
  isPhoneLengthValid = (phoneNumber: any, countryCode: any) => {
    const shortCountryCodes = [
      "43", "32", "45", "358", "354", "353", "972", "352", "64", "47", "351", "65", "41"
    ];
  
    const isShortCountryCode = shortCountryCodes.includes(countryCode);
    const minLength = isShortCountryCode ? 10 : 12;
    return phoneNumber.length >= minLength;
  };
  
  handlePhoneChange = (value: any, countryData: any) => {
    const countryCode = countryData?.dialCode || '';
  
    this.setState((prevState) => ({
      formDataValue: {
        ...prevState.formDataValue,
        full_phone_number: value,
        country_code: countryCode
      },
      isPhoneError: !this.isPhoneLengthValid(value, countryCode),
      phoneErrorMessageFromBE:""
    }));
  };
  
  

  goToHome = () => {
    this.props.navigation.navigate("LandingPage");
  }
  goToHelpCenterTeam() {
    this.props.navigation.navigate("HelpCenter");
  }

  
  goToProfileTeam = () => {
    this.props.navigation.navigate("CustomisableuserProfiles");
  }
  
  handleOpen = (item: any) => {
    this.setState({ currentItem: item, showDeleteModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showDeleteModal: false })
  }

  handleCloseCreateModal = () => {
    this.setState({ createTeamMemberModal: false,
    showUpdateSuccessMsg: false,
    showUpdateModal:false,openModel:"", isEmailError:false,isPhoneError:false,phoneErrorMessageFromBE:""})
    
    
  }

  handleVerificatonModal = () => {
    this.setState({ showVerificationModal: true,openModel:"" });
  };

  handleCloseVerificationModal = () => {
    this.setState({ showVerificationModal: false })
  }

  handleOpenCreateModal = () => {
    this.setState({
      formDataValue: {
        firstName: '',
        lastName: '',
        emailId: '',
        company: '',
        full_phone_number: '',
        userType: '',
        jobTitle: '',
        companyType: this.state.companyAccount,
        country_code: 0
      },
    });
    if(this.state.can_create_ac_teamMembers == true){
      this.setState({ createTeamMemberModal: true ,openModel:""})
    }else{
      this.setState({ createTeamMemberModal: false ,openModel:"Error",warningMessage:"You don't have permission to perform this action"})
    }
  }

  handleUpdateModal= (item:any) => {
    const [pre , phone ] = item.attributes.full_phone_number.split(" ");
    this.setState({showUpdateModal :true,
      formDataValue: {
        firstName:item.attributes.first_name,
        lastName: item.attributes.last_name,
        company: item.attributes.company.name,
        emailId: item.attributes.email,
        full_phone_number: item.attributes.full_phone_number,
        country_code: item.attributes.country_code,
        companyType: item.attributes.account_type,
        jobTitle: item.attributes.job_title,
        userType: item.attributes.type,
        
      },
    });
    
    this.setState({
      clientUserId:item.id
    })

  }
  handleDeleteclose = () => {
    this.setState({ showDeleteModal: false })
  }

  handlePageChange = (newPage: any) => {
    const { Teams } = this.state;
    const startIndex = (newPage-1)*this.state.itemsPerPage;
    const endIndex = startIndex+this.state.itemsPerPage;
    this.setState({
      currentTeams :Teams.slice(startIndex,endIndex),
      currentPage : newPage
    }) ;

    
  };

  handleSearchValue = (search: string) => {
    this.setState({ searchMember: search});
  }
  
  handleSearch=(search:string)=>{
    
    const header = {"Content-Type": configJSON.validationApiContentType, token:this.state.token}

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
   
    this.getSearchApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.searchApi}?query=${search}`); 

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
 }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiCallId != null) {
        switch (apiCallId) {
          case this.getSearchApiCallId:
          case this.getTeamMemberListCallId:
            this.fnTeamListRes(responseJson)
            break;
          case this.deleteTeamMemberDataCallId:
            this.handleDeleteclose()
            this.getTeamMemberListApi();
            break;
          case this.updateMembersApiCallId:
            this.setState({ showUpdateModal: false });
            this.setState({showUpdateSuccessMsg:true})
            this.getTeamMemberListApi();
            break;
          case this.addMembersApiCallId:
            this.fnAddMembersRes(responseJson)
            break;
          case this.verificationEmailApiCallId:
            this.fnVerificationEmailRes(responseJson)
            break;
          case this.popUpconfrimembersApiCallId:
            this.fnVerificationEmailRes(responseJson)
            break;
          case this.phoneNumberValidateApiCallId:
            this.handlePhoneNumberValidationResponse(responseJson)
            break;
          case this.getProfileAPICallId:
            this.setState({ 
              companyName: responseJson.data.attributes.company.name ,
              can_create_ac_teamMembers:responseJson.data.attributes.can_create_accounts,
              companyAccount: responseJson.data.attributes.account_type
            })
          case this.userSuspendedApiCallId:
            this.handleIsUserSuspended(message);
          break;
        }
      } 
    }
  }

  

  fnTeamListRes = (responseJson :any) =>{
    if (responseJson) {
      this.setState({ Teams: responseJson.company_users.data })
    }
    this.setState({
      totalPage: this.state.Teams.length / this.state.itemsPerPage,isLoading:false
    })
    this.handlePageChange(1);

  }

  fnAddMembersRes = (responseJson :any) =>{
    this.handleCloseCreateModal()
   if(responseJson.error === null){
      this.popMemberAPICall()
    }else if(responseJson.error){
      this.setState({openModel:"Confirmation",warningMessage:responseJson.error})
    }
    this.getTeamMemberListApi();
  }

  validateFormFields = () => {
    const { formDataValue } = this.state;
    const { emailId } = formDataValue;

    if (
      formDataValue.firstName === "" ||
      formDataValue.companyType === "" ||
      formDataValue.lastName === "" ||
      formDataValue.full_phone_number === "" ||
      formDataValue.jobTitle === "" ||
      emailId === ""
    ) {
      return true;
    }
  };

  validateFormFieldsForEditClientUser = ():boolean => {
    const { formDataValue } = this.state;
    if (
      formDataValue.firstName === "" ||
      formDataValue.companyType === "" ||
      formDataValue.lastName === "" ||
      formDataValue.full_phone_number === "" ||
      formDataValue.jobTitle === "" ||
      formDataValue.emailId === "" || formDataValue.userType === "" || formDataValue.company === ""
    ) {
      return true;
    }
    return false
  };

  validateEmail=(value:any)=>{
    return emailRegex.test(value);
  }

  validatePhone=()=>{
    const { full_phone_number, country_code } = this.state.formDataValue;
    const isValid = this.isPhoneLengthValid(full_phone_number, country_code);
    return isValid;
  }

  handleSubmit=(e:any)=>{

    if(this.validateFormFields()){
      this.setState({isErrorFields : true,createTeamMemberModal: true});
    }
    else if(!this.validateEmail(e)){
      this.setState({isEmailError : true,createTeamMemberModal: true});
    }
    else if(!this.validatePhone()){
      this.setState({isPhoneError : true, createTeamMemberModal: true});
    }
    else{
      this.validatePhoneNumber()
    }

  }
  

  fnVerificationEmailRes = (responseJson :any) =>{
    if (responseJson.message) {
      if(responseJson.errors){
        this.setState({openModel:"Error",warningMessage:responseJson.errors})
      }else{
      this.setState({
        formDataValue: {
          firstName: '',
          lastName: '',
          emailId: '',
          company: '',
          full_phone_number: '',
          userType: '',
          jobTitle: '',
          companyType: '',
          country_code: 0
        },
        successModal: true ,openModel:""
      });
      }
    }else{
      this.setState({openModel:"Error",warningMessage:responseJson.errors})
    }
  }

  handlePhoneNumberValidationResponse = (responseJson : PhoneNumberResponse) => {
    if(responseJson.errors){
      this.setState({ phoneErrorMessageFromBE: responseJson?.errors})
    }else{
      this.addMemberAPICall();
      this.setState({isErrorFields:false,isEmailError:false,isPhoneError:false})
    }
  }

  async componentDidMount() {
      let isUserLoggedIn = localStorage.getItem("authToken") || "";
      if (!isUserLoggedIn) {
        this.handleNavigationLogin()
      } else {
        this.setState({ token: isUserLoggedIn }, () => this.getTeamMemberListApi())
      }
      window.addEventListener("mousemove", this.checkIsUserIsSuspended)

      const queryParams = new URLSearchParams(window.location.search);
      const userId = queryParams.get('id');
    
      if (userId) {
        this.scrollToUser(userId);
      } 
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get('id');
  
    if (userId && prevState.Teams != this.state.Teams) {
      this.scrollToUser(userId);
    } 
}

  async componentWillUnmount() {
    window.removeEventListener("mousemove", this.checkIsUserIsSuspended)
  }

  scrollToUser = (userId: any) => {
      const { Teams, itemsPerPage } = this.state;

      const userIndex = Teams.findIndex(user => user.id === userId);
      
      if (userIndex !== -1) {
          const targetPage = Math.floor(userIndex / itemsPerPage) + 1;
          if (targetPage !== this.state.currentPage) {
              setTimeout(() => {this.scrollToUserOnPage(userId, targetPage)}, 500); 
          } else {
              this.scrollToUserOnPage(userId, targetPage);
          }
      }
  };

  scrollToUserOnPage = (userId: any, page: any) => {
    this.handlePageChange(page)
    setTimeout(() => {
      const userRow = document.querySelector(`[data-user-id="${userId}"]`);
      if (userRow) {
          userRow.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 500); 
  };

  checkIsUserIsSuspended = _.throttle(async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };
    this.userSuspendedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.suspendedUserEndPoint 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  },9000)

  handleIsUserSuspended = async(message:Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const checIsTokenExpired = async (): Promise<boolean> => {
      const tokenValidTime = await getStorageData("tokenValidTime")
      const currentTime = new Date().toISOString()
      if (!tokenValidTime) return false
      return new Date(tokenValidTime) < new Date(currentTime)
    }

    if (responseJson.status && responseJson.status === "suspended") {
      this.setState({
        isSuspended: true,
        suspendedMessage: responseJson.message,
        suspendedTitle: configJSON.supsendedModalTitle
      })
    } else if (responseJson.status && responseJson.status === "deactivated") {
      this.setState({
        isSuspended: true,
        suspendedMessage: responseJson.message,
        suspendedTitle: configJSON.deactivatedTitle
      })
    } else if (await checIsTokenExpired()) {
      this.handleGoToLoginPage()
    }
  }

  handleGoToLoginPage = async () => {
    await removeStorageData("authToken")
    await removeStorageData("role")
    await removeStorageData("tokenValidTime")
    await removeStorageData("userName")
    this.setState({ isSuspended: false, suspendedMessage: "", suspendedTitle: "" })
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleNavigationLogin = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationPropsMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  };

  handleActiovationStatusText = (activated: any) => {
    return activated ? 'Activated' : 'Pending';
  }

  handleDeleteTeamMember = (item: []) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.deleteTeamMemberDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/remove_user?user_id=${item}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  verificationEmailApi = (id:number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.verificationEmailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/send_account_activation_email?user_id=${id}`
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

 



  addMemberAPICall = async () => {
    const header = {"Content-Type": configJSON.validationApiContentType,token: await getStorageData("authToken")};


    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.addMembersApiCallId = 
    requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`/match_company_domain?email=${this.state.formDataValue.emailId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, 
      requestMessage);
  };

  validatePhoneNumber = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken")
    };
    const body = {
      full_phone_number: this.state.formDataValue.full_phone_number,
      country_code: this.state.formDataValue.country_code
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.phoneNumberValidateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/check_phone_number`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  popMemberAPICall = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken")
    };
    const formData = {
      account: {
        first_name: this.state.formDataValue.firstName,
        last_name: this.state.formDataValue.lastName,
        email: this.state.formDataValue.emailId,
        full_phone_number: this.state.formDataValue.full_phone_number,
        account_type: this.state.formDataValue.companyType.toLowerCase(),
        job_title: this.state.formDataValue.jobTitle,
        company_id: this.state.companyName,
        country_code: this.state.formDataValue.country_code
      } 
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.popUpconfrimembersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/add_client_user`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };





  getTeamMemberListApi = () => {
    this.getProfileAccountApi()
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTeamMemberListCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTeamMemberEndPoint
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  getProfileAccountApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileAPICallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileAccountEndPoint
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  updateMemberAPICall = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken")
    };
    const formData = {
      client_user_id : this.state.clientUserId,
        account: {
          first_name: this.state.formDataValue.firstName,
          last_name: this.state.formDataValue.lastName,
          full_phone_number :this.state.formDataValue.full_phone_number,
          account_type: this.state.formDataValue.companyType.toLowerCase(),
          job_title: this.state.formDataValue.jobTitle,
          email:this.state.formDataValue.emailId,      
        }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateMembersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTeamMemeberEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    goToSettingsTeam(){const msg: Message = 
      new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), 
      this.props);
      this.send(msg);
      }
      goToQuote = () =>{
        const msg: Message = 
        new Message(
          getName(MessageEnum.NavigationMessage)
        );
      msg.addData(
        getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories"
      );
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
       this.props
       );
      this.send(msg);
      }
      
      goToEnquiryTeam = () =>{
      const msg: Message = new 
      Message(getName(
        MessageEnum.NavigationMessage
        ));
      msg.addData(getName(MessageEnum.NavigationTargetMessage
        ), 
        "Enquiries");
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage), 
        this.props
        );
      this.send(
        msg
        );
  }

  hideModal = () =>{
    this.setState({
      successModal:false
    })
    this.getTeamMemberListApi();
  }

}

// Customizable Area End
